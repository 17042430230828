import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Loader from "../Loader";
import PageBanner from "../PageBanner";

const PrivacyPolicy = () => {
  const [privacyData, setPrivacyData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    window.scrollTo(0, 0);
  }, []);

  const getPrivacyData = async () => {
    return await fetch(
      "https://moneyogi.com/admin/api/GetPrivacyPolicy"
    )
      .then((res) => res.json())
      .then((res) => {
        setPrivacyData(res.data);
      });
  };

  useEffect(() => {
    getPrivacyData();
  }, []);

  return (
    <div>
      {loading && <Loader />}
      {/* <PageBanner title={"Privacy Policy"} pageTitle="Privacy Policy" /> */}
      <div className="container section-padding pPage">
        <div className="p-6">
          <h2>{privacyData.title}</h2>
          <p>{privacyData.description}</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
