import React from "react";
import { Link } from "react-router-dom";
import { BlogsData } from "../Context/Data";
import calender from "../assets/images/svg/calender.svg";
import { useAPI } from "../Context/apiContext";

const Blogs = () => {
  const { blogsData } = useAPI();
  return (
    <div className=" section-padding bg-[url('../images/all-img/section-bg-5.png')] bg-cover bg-no-repeat bg-center">
      <div className="container">
        <div className="lg:flex justify-between items-center ">
          <div className="mb-3">
            {/* <div className="mini-title">Team Member</div> */}
            <div className="column-title ">
              Moneyogi Mondays <span className="shape-bg">Blogs</span>
            </div>
          </div>
          <div className="mb-3">
            <Link to={"/blog"} className=" btn btn-primary">
              Read All Blogs
            </Link>
          </div>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] pt-7">
          
          {blogsData.slice(0, 3).map((item) => {
            return (
              <div className=" bg-white shadow-box5 rounded-[8px] transition duration-100 hover:shadow-box3">
                <div className="course-thumb h-[260px] rounded-t-[8px]  relative">
                  <img
                    src={item.image}
                    alt=""
                    className=" w-full h-full object-cover rounded-t-[8px]"
                  />
                </div>
                <div className="course-content p-8">
                  <div className="flex lg:space-x-10 space-x-5 mb-5">
                    <Link
                      to={`/blog-detail/${item.id}`}
                      className=" flex items-center space-x-2"
                    >
                      <img src={calender} alt="" />
                      <span>{item.date}</span>
                    </Link>
                  </div>
                  <h4 className="text-xl mb-3  font-bold">
                    <Link
                      to={`/blog-detail/${item.id}`}
                      className=" hover:text-primary transition duration-150"
                    >
                      {item.title}
                    </Link>
                  </h4>
                  <Link
                    to={`/blog-detail/${item.id}`}
                    className=" text-black font-semibold hover:underline transition duration-150"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            );
          })}
          {/* {BlogsData.map((item) => {
            return (
              <div className=" bg-white shadow-box5 rounded-[8px] transition duration-100 hover:shadow-box3">
                <div className="course-thumb h-[260px] rounded-t-[8px]  relative">
                  <img
                    src={item.img}
                    alt=""
                    className=" w-full h-full object-cover rounded-t-[8px]"
                  />
                </div>
                <div className="course-content p-8">
                  <div className="flex lg:space-x-10 space-x-5 mb-5">
                    <Link to={"#"} className=" flex items-center space-x-2">
                      <img src={calender} alt="" />
                      <span>{item.date}</span>
                    </Link>
                  </div>
                  <h4 className="text-xl mb-3  font-bold">
                    <Link
                      to={"#"}
                      className=" hover:text-primary transition duration-150"
                    >
                      {item.title}
                    </Link>
                  </h4>
                  <Link
                    to={"#"}
                    className=" text-black font-semibold hover:underline transition duration-150"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            );
          })} */}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
