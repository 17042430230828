import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative, Navigation } from "swiper";
import { t1 } from "../../constant/images";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { FreeMode, Pagination } from "swiper";
import { useAPI } from "../../Context/apiContext";
import { Link } from "react-router-dom";
import testAvatar from "../../assets/images/Images/Test/testAvatar.jpg";
import test1 from "../../assets/images/Images/Test/test1.jpg";
import test2 from "../../assets/images/Images/Test/test2.jpg";
import test3 from "../../assets/images/Images/Test/test3.jpg";
import thumbnail from "../../assets/images/Images/Test/thumbnail.jpg";
import { BsPlayCircle } from "react-icons/bs";
import CountUp from "react-countup";

const Testimonial = () => {
  const prevBtn = useRef(null);
  const nextBtn = useRef(null);

  const { testData, coutersData } = useAPI();
  const [testPara, setTestPara] = useState(true);

  const [ButtonPopup, setButtonPopup] = useState(false);

  const [id, setId] = useState("");

  const [showPopup, setShowpopup] = useState(0);
  const togglePopup = (index) => {
    setShowpopup(index);
  };

  return (
    <>
      {/*  */}
      <div className="secPadding bg-[url('../images/all-img/section-bg-16.png')]  bg-no-repeat bg-cover mTop">
        {/* xl:pb-[136px] */}
        <div className="container lg:-mt-[250px] lg:pb-20 pb-10">
          <div
            className="bg-white shadow-box14 rounded-md
              divide-x-2 divide-[#E4EEED] pt-20 pb-8"
          >
            <h4
              className="p-5 loraFont"
              style={{
                textAlign: "center",
                color: "#000",
                lineHeight: "30px",
                marginTop: "-3rem",
                marginBottom: "1.2rem",
                fontSize: "25px",
              }}
            >
              Move from living a life full of worries and stress to one filled
              with meaning and peace of mind enabled by a robust and sustainable
              money management system
            </h4>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></div>
            <div
              style={{ textTransform: "uppercase" }}
              className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[0px]"
            >
              <div className="text-center">
                <h2 className="text-secondary font-bold ">
                  <CountUp className=" counter" end={6700} duration={3} />
                </h2>
                <span className="block text-black font-semibold">MENTEES</span>
              </div>
              <div className="text-center">
                <h2 className="text-secondary font-bold ">
                  <CountUp className=" counter" end={13} duration={5} />+
                </h2>
                <span className="block text-black font-semibold">
                  COUNTRIES
                </span>
              </div>
              <div className="text-center">
                <h2 className="text-secondary font-bold ">
                  <CountUp className=" counter" end={319} duration={3} />+
                </h2>
                <span className="block text-black font-semibold">
                  Happy Clients
                </span>
              </div>
              {/* <div className="text-center">
              <h2 className="text-secondary font-bold ">
                <CountUp className=" counter" end={62} duration={3} />
                M+
              </h2>
              <span className="block text-black font-semibold">GENERATED</span>
            </div> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="text-center">
            {/* <div className="mini-title">Start Learning From Today</div> */}
            <h4 className="column-title ">
              Success
              <span className="shape-bg" style={{ marginLeft: "8px" }}>
                Stories
              </span>
            </h4>
          </div>
          <Swiper
            slidesPerView={4}
            spaceBetween={30}
            freeMode={true}
            loop={true}
            className="mySwiper"
            navigation={true}
            modules={[Navigation, Pagination]}
            style={{ position: "relative" }}
            breakpoints={{
              375: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              390: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              414: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
          >
            <SwiperSlide
              className=" rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8]  text-center px-6
      py-[65px] hover:-translate-y-2"
            >
              {/* #FFE8E8 */}
              <Link to="#" href="#">
                <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8]">
                  <img
                    src={test1}
                    alt=""
                    className="w-[72px] h-[72px] object-cover"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <div
                  className="course-content"
                  style={{ position: "relative" }}
                >
                  <h4 className=" text-2xl  mb-2 font-bold">MR. ANKIT GOEL</h4>
                  <p>Managing Director</p>
                  <p className="readMore">
                    Mr. Suhas Harshe, has been one of the biggest steps in my
                    career and I would say its a privilege to be lucky enough
                    <Link
                      to={"#"}
                      onClick={() => togglePopup(1)}
                      style={{ marginLeft: "4px" }}
                    >
                      Read More...
                    </Link>
                  </p>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide
              className=" rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8]  text-center px-6
      py-[65px] hover:-translate-y-2"
            >
              <Link to="#" href="#">
                <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8]">
                  <img
                    src={test2}
                    alt=""
                    className="w-[72px] h-[72px] object-cover"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <div
                  className="course-content"
                  style={{ position: "relative" }}
                >
                  <h4 className=" text-2xl  mb-2 font-bold">MR. ALOK MUNOT</h4>
                  <p>CEO</p>
                  <p className="readMore">
                    I am very thankful to BNI that we met. Thank you for taking
                    us through such a necessary exercise of financial
                    <Link
                      to={"#"}
                      onClick={() => togglePopup(2)}
                      style={{ marginLeft: "4px" }}
                    >
                      Read More...
                    </Link>
                  </p>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide
              className=" rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8]  text-center px-6
      py-[65px] hover:-translate-y-2"
            >
              <Link to="#" href="#">
                <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8]">
                  <img
                    src={test3}
                    alt=""
                    className="w-[72px] h-[72px] object-cover"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <div
                  className="course-content"
                  style={{ position: "relative" }}
                >
                  <h4 className=" text-2xl  mb-2 font-bold">GIRISH NANGARE</h4>
                  <p>Director</p>
                  <p className="readMore">
                    After doing 1 to 1 with Suhas I was clear that I needed some
                    financial discipline need to initiate on overall
                    <Link
                      to={"#"}
                      onClick={() => togglePopup(3)}
                      style={{ marginLeft: "4px" }}
                    >
                      Read More...
                    </Link>
                  </p>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide
              className=" rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8]  text-center px-6
      py-[65px] hover:-translate-y-2"
            >
              <Link to="#" href="#">
                <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8]">
                  <img
                    src={testAvatar}
                    alt=""
                    className="w-[72px] h-[72px] object-cover"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <div
                  className="course-content"
                  style={{ position: "relative" }}
                >
                  <h4 className=" text-2xl  mb-2 font-bold">Prasad Dhumal</h4>
                  <p>Role Name</p>
                  <div
                    style={{ position: "relative" }}
                    onClick={() => togglePopup(4)}
                  >
                    <img
                      src={thumbnail}
                      alt=""
                      style={{ borderRadius: "5px" }}
                      className="testImage"
                    />
                    <div className="overlayTest"></div>
                    <BsPlayCircle className="icoPlay" />
                  </div>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide
              className=" rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8]  text-center px-6
      py-[65px] hover:-translate-y-2"
            >
              <Link to="#" href="#">
                <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8]">
                  <img
                    src={testAvatar}
                    alt=""
                    className="w-[72px] h-[72px] object-cover"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <div
                  className="course-content"
                  style={{ position: "relative" }}
                >
                  <h4 className=" text-2xl  mb-2 font-bold">
                    Aditya Dhamankar
                  </h4>
                  <p>Role Name</p>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide
              className=" rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8]  text-center px-6
      py-[65px] hover:-translate-y-2"
            >
              <Link to="#" href="#">
                <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8]">
                  <img
                    src={testAvatar}
                    alt=""
                    className="w-[72px] h-[72px] object-cover"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <div
                  className="course-content"
                  style={{ position: "relative" }}
                >
                  <h4 className=" text-2xl  mb-2 font-bold">Vijay Paradkar</h4>
                  <p>Role Name</p>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide
              className=" rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8]  text-center px-6
      py-[65px] hover:-translate-y-2"
            >
              <Link to="#" href="#">
                <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8]">
                  <img
                    src={testAvatar}
                    alt=""
                    className="w-[72px] h-[72px] object-cover"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <div
                  className="course-content"
                  style={{ position: "relative" }}
                >
                  <h4 className=" text-2xl  mb-2 font-bold">Shantanu Gadve</h4>
                  <p>Role Name</p>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide
              className=" rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8]  text-center px-6
      py-[65px] hover:-translate-y-2"
            >
              <Link to="#" href="#">
                <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8]">
                  <img
                    src={testAvatar}
                    alt=""
                    className="w-[72px] h-[72px] object-cover"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <div
                  className="course-content"
                  style={{ position: "relative" }}
                >
                  <h4 className=" text-2xl  mb-2 font-bold">
                    Gurinder Pal Singh
                  </h4>
                  <p>Role Name</p>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide
              className=" rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8]  text-center px-6
      py-[65px] hover:-translate-y-2"
            >
              <Link to="#" href="#">
                <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8]">
                  <img
                    src={testAvatar}
                    alt=""
                    className="w-[72px] h-[72px] object-cover"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <div
                  className="course-content"
                  style={{ position: "relative" }}
                >
                  <h4 className=" text-2xl  mb-2 font-bold">Latchmann Dass</h4>
                  <p>Role Name</p>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide
              className=" rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8]  text-center px-6
      py-[65px] hover:-translate-y-2"
            >
              <Link to="#" href="#">
                <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8]">
                  <img
                    src={testAvatar}
                    alt=""
                    className="w-[72px] h-[72px] object-cover"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <div
                  className="course-content"
                  style={{ position: "relative" }}
                >
                  <h4 className=" text-2xl  mb-2 font-bold">
                    Nikhil Deshpande
                  </h4>
                  <p>Role Name</p>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide
              className=" rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8]  text-center px-6
      py-[65px] hover:-translate-y-2"
            >
              <Link to="#" href="#">
                <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8]">
                  <img
                    src={testAvatar}
                    alt=""
                    className="w-[72px] h-[72px] object-cover"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <div
                  className="course-content"
                  style={{ position: "relative" }}
                >
                  <h4 className=" text-2xl  mb-2 font-bold">
                    Austin Fernandes
                  </h4>
                  <p>Role Name</p>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide
              className=" rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8]  text-center px-6
      py-[65px] hover:-translate-y-2"
            >
              <Link to="#" href="#">
                <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8]">
                  <img
                    src={testAvatar}
                    alt=""
                    className="w-[72px] h-[72px] object-cover"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <div
                  className="course-content"
                  style={{ position: "relative" }}
                >
                  <h4 className=" text-2xl  mb-2 font-bold">Arjun Chaurasia</h4>
                  <p>Role Name</p>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide
              className=" rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8]  text-center px-6
      py-[65px] hover:-translate-y-2"
            >
              <Link to="#" href="#">
                <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8]">
                  <img
                    src={testAvatar}
                    alt=""
                    className="w-[72px] h-[72px] object-cover"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <div
                  className="course-content"
                  style={{ position: "relative" }}
                >
                  <h4 className=" text-2xl  mb-2 font-bold">Abhishek Sharma</h4>
                  <p>Role Name</p>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide
              className=" rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8]  text-center px-6
      py-[65px] hover:-translate-y-2"
            >
              <Link to="#" href="#">
                <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8]">
                  <img
                    src={testAvatar}
                    alt=""
                    className="w-[72px] h-[72px] object-cover"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <div
                  className="course-content"
                  style={{ position: "relative" }}
                >
                  <h4 className=" text-2xl  mb-2 font-bold">Sujata Upadhyay</h4>
                  <p>Role Name</p>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide
              className=" rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8]  text-center px-6
      py-[65px] hover:-translate-y-2"
            >
              <Link to="#" href="#">
                <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8]">
                  <img
                    src={testAvatar}
                    alt=""
                    className="w-[72px] h-[72px] object-cover"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <div
                  className="course-content"
                  style={{ position: "relative" }}
                >
                  <h4 className=" text-2xl  mb-2 font-bold">Amit Koshal</h4>
                  <p>Role Name</p>
                </div>
              </Link>
            </SwiperSlide>
          </Swiper>
          <Link
            to="/testmonial"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button className="btn px-8 py-[11px] mt-4 btn-primary text-center">
              Read All Success Stories
            </button>
          </Link>
          <div className="grid lg:grid-cols-4 md:grid-cols-2  grid-cols-1 gap-[30px]">
            {showPopup === 1 ? (
              <div className="modalVideo">
                <div className="modalOverlay"></div>
                <div className="centerModal">
                  <div className="bg-[url('../images/all-img/section-bg-12.png')] bg-no-repeat bg-cover">
                    <Swiper
                      navigation={{
                        prevEl: ".slickprev",
                        nextEl: ".slicknext",
                      }}
                      spaceBetween={30}
                      loop
                      effect={"creative"}
                      creativeEffect={{
                        prev: {
                          shadow: true,
                          translate: [0, 0, -400],
                          opacity: [0],
                        },
                        next: {
                          translate: ["100%", 0, 0],
                        },
                      }}
                      modules={[Navigation, EffectCreative]}
                      className="container"
                    >
                      <>
                        <div className="grid  lg:grid-cols-2 grid-cols-1  xl:gap-[30px] gap-6 mt-19">
                          <div>
                            <div className="slider-nav">
                              <div className="single-item">
                                <div className="xl:h-[297px] lg:h-[400px] h-[150px] lg:w-full w-[320px] rounded-md">
                                  <img
                                    src={test1}
                                    alt=""
                                    className=" object-cover w-full h-full rounded-md testImg"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="mini-title titleTest">
                              Testimonial
                            </div>
                            <div className="slider-for mt-10">
                              <div className="single-item">
                                <div>
                                  <p className="mb-8 para">
                                    “Mr. Suhas Harshe, has been one of the
                                    biggest steps in my career and I would say
                                    its a privilege to be lucky enough to meet
                                    him, at a critical age of 25, when career
                                    decisions are a big worry. In a span of 2
                                    hours, he has helped me envision the next 30
                                    years of my life and I couldn't have been
                                    sure of what I expect from myself till my
                                    end and how I am going to achieve
                                    everything.”
                                  </p>
                                  <div>
                                    <span className="block  font-semibold text-black mb-1 testName">
                                      MR. ANKIT GOEL
                                    </span>
                                    <span className="block  font-semibold text-primary testDesg">
                                      Managing Director
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </Swiper>
                  </div>
                </div>
                <div className="closeModal" onClick={() => togglePopup(null)}>
                  <i class="fa-solid fa-xmark"></i>
                </div>
              </div>
            ) : null}
            {showPopup === 2 ? (
              <div className="modalVideo">
                <div className="modalOverlay"></div>
                <div className="centerModal">
                  <div className="bg-[url('../images/all-img/section-bg-12.png')] bg-no-repeat bg-cover">
                    <Swiper
                      navigation={{
                        prevEl: ".slickprev",
                        nextEl: ".slicknext",
                      }}
                      spaceBetween={30}
                      loop
                      effect={"creative"}
                      creativeEffect={{
                        prev: {
                          shadow: true,
                          translate: [0, 0, -400],
                          opacity: [0],
                        },
                        next: {
                          translate: ["100%", 0, 0],
                        },
                      }}
                      modules={[Navigation, EffectCreative]}
                      className="container"
                    >
                      <>
                        <div className="grid  lg:grid-cols-2 grid-cols-1  xl:gap-[30px] gap-6 mt-19">
                          <div>
                            <div className="slider-nav">
                              <div className="single-item">
                                <div className="xl:h-[297px] lg:h-[400px] h-[150px] lg:w-full w-[320px] rounded-md">
                                  <img
                                    src={test2}
                                    alt=""
                                    className=" object-cover w-full h-full rounded-md testImg"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="mini-title titleTest">
                              Testimonial
                            </div>
                            <div className="slider-for mt-10">
                              <div className="single-item">
                                <div>
                                  <p className="mb-8 para">
                                    "I am very thankful to BNI that we met.
                                    Thank you for taking us through such a
                                    necessary exercise of financial planning,
                                    The systems developed by you are very
                                    applicable, effective and essential to
                                    everyone who would implement the same. Your
                                    patience, methodological approach and
                                    accurate advice is very useful tool to
                                    actually enable others live their dreams.
                                    What the God say about you is simply 100%
                                    correct "Laxmi through Saraswati Thank you
                                    very much for your valuable guidance & look
                                    forward to your kind support."
                                  </p>
                                  <div>
                                    <span className="block  font-semibold text-black mb-1 testName">
                                      MR. ALOK MUNOT
                                    </span>
                                    <span className="block  font-semibold text-primary testDesg">
                                      Managing Director
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </Swiper>
                  </div>
                </div>
                <div className="closeModal" onClick={() => togglePopup(null)}>
                  <i class="fa-solid fa-xmark"></i>
                </div>
              </div>
            ) : null}
            {showPopup === 3 ? (
              <div className="modalVideo">
                <div className="modalOverlay"></div>
                <div className="centerModal">
                  <div className="bg-[url('../images/all-img/section-bg-12.png')] bg-no-repeat bg-cover">
                    <Swiper
                      navigation={{
                        prevEl: ".slickprev",
                        nextEl: ".slicknext",
                      }}
                      spaceBetween={30}
                      loop
                      effect={"creative"}
                      creativeEffect={{
                        prev: {
                          shadow: true,
                          translate: [0, 0, -400],
                          opacity: [0],
                        },
                        next: {
                          translate: ["100%", 0, 0],
                        },
                      }}
                      modules={[Navigation, EffectCreative]}
                      className="container"
                    >
                      <>
                        <div className="grid  lg:grid-cols-2 grid-cols-1  xl:gap-[30px] gap-6 mt-19">
                          <div>
                            <div className="slider-nav">
                              <div className="single-item">
                                <div className="xl:h-[297px] lg:h-[400px] h-[150px] lg:w-full w-[320px] rounded-md">
                                  <img
                                    src={test3}
                                    alt=""
                                    className=" object-cover w-full h-full rounded-md testImg"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="mini-title titleTest">
                              Testimonial
                            </div>
                            <div className="slider-for mt-10">
                              <div className="single-item">
                                <div>
                                  <p className="mb-8 para">
                                    “Mr. Suhas Harshe, has been one of the
                                    biggest steps in my career and I would say
                                    its a privilege to be lucky enough to meet
                                    him, at a critical age of 25, when career
                                    decisions are a big worry. In a span of 2
                                    hours, he has helped me envision the next 30
                                    years of my life and I couldn't have been
                                    sure of what I expect from myself till my
                                    end and how I am going to achieve
                                    everything.”
                                  </p>
                                  <div>
                                    <span className="block  font-semibold text-black mb-1 testName">
                                      MR. ANKIT GOEL
                                    </span>
                                    <span className="block  font-semibold text-primary testDesg">
                                      Managing Director
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </Swiper>
                  </div>
                </div>
                <div className="closeModal" onClick={() => togglePopup(null)}>
                  <i class="fa-solid fa-xmark"></i>
                </div>
              </div>
            ) : null}
            {showPopup === 4 ? (
              <div className="modalVideo">
                <div className="modalOverlay"></div>
                <div className="centerModal">
                  <div className="bg-[url('../images/all-img/section-bg-12.png')] bg-no-repeat bg-cover">
                    <div>
                      <div className="rounded-md">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/D0UnqGm_miA"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                          className=" object-cover w-full h-full testIframe"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="closeModal" onClick={() => togglePopup(null)}>
                  <i class="fa-solid fa-xmark"></i>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
