import React from "react";

const NextArrwo = (props) => {
  const { onClick } = props;
  return (
    <div className="arrowRight" onClick={onClick}>
      <i class="fa-solid fa-arrow-right"></i>
    </div>
  );
};

export default NextArrwo;
