import React from "react";
import { Link } from "react-router-dom";
import { Programs } from "../Context/Data";
import { useAPI } from "../Context/apiContext";

const Courses = () => {
  const { servicesData } = useAPI();
  return (
    <div className=" section-padding">
      <div className="container">
        <div className="text-center">
          {/* <div className="mini-title">Popular Services</div> */}
          <div className="column-title ">
            Our Top <span className="shape-bg">Programs</span>
            <h4
              className="loraFont"
              style={{
                textAlign: "center",
                color: "#000",
              }}
            >
              THESE PROGRAMS CAN CHANGE YOUR LIFE FOREVER
            </h4>
          </div>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] pt-1">
          {/* {servicesData.slice(0, 3).map((item, index) => {
            const noTagsDesc = item.description.replace(/<\/?[^>]+(>|$)/g, "");
            return (
              <div
                className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
                key={index}
              >
                <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
                  <Link to={`/service-detail/${item.id}`}>
                    <img
                      src={item.image}
                      alt=""
                      className=" w-full h-full rounded-t-[8px]"
                    />
                  </Link>
                </div>
                <div className="course-content p-8">
                  <Link to={`/service-detail/${item.id}`}>
                    <h4 className=" text-xl mb-3 font-bold">{item.title}</h4>
                    <p>{noTagsDesc.slice(0, 82)}...</p>
                  </Link>
                  <Link to={`/service-detail/${item.id}`}>
                    <button className="btn px-8 py-[11px] mt-4 btn-primary text-center">
                      Read More
                    </button>
                  </Link>
                </div>
              </div>
            );
          })} */}
          {Programs.slice(0, 3).map((item) => {
            return (
              <div className="bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm borderCard">
                <div className="course-thumb h-[248px] rounded-t-[8px]  relative bottomCard">
                  <Link to={item.link}>
                    <img
                      src={item.img}
                      alt=""
                      className=" w-full h-full rounded-t-[8px] "
                    />
                  </Link>
                </div>
                <div className="course-content p-8">
                  <Link to={item.link}>
                    <h4 className=" text-xl mb-3 font-bold">{item.title}</h4>
                    <p>{item.desc}</p>
                  </Link>
                  <Link to={item.link}>
                    <button className="btn px-8 py-[11px] mt-4 btn-primary text-center">
                      Read More
                    </button>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Courses;
