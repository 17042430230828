import React, { useEffect } from "react";
import { ProgramData7 } from "../../Context/Data";
import { Link } from "react-router-dom";
import { IoIosDesktop } from "react-icons/io";
import { FiClock } from "react-icons/fi";

const Program7 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <>
        <div className="nav-tab-wrapper tabs  section-padding">
          {ProgramData7.map((item) => {
            return (
              <div className="container rel">
                <div className="grid grid-cols-12 gap-[30px]">
                  <div className="lg:col-span-8 col-span-12">
                    <div className="single-course-details">
                      <div className="xl:h-[470px] h-[350px] mb-10 course-main-thumb">
                        <img
                          src={item.img}
                          alt=""
                          className=" rounded-md object-fut w-full h-full block"
                        />
                      </div>
                      <div className=" mb-6"></div>
                      <h2>{item.title}</h2>
                      <p>{item.desc}</p>
                      <div
                        className="author-meta mt-6 sm:flex  lg:space-x-16 sm:space-x-5 space-y-5 
           sm:space-y-0 items-center"
                      ></div>
                    </div>
                  </div>
                  <div className="lg:col-span-4 col-span-12 widgetPosition widgetPosi">
                    <div
                      className="sidebarWrapper space-y-[30px]"
                      style={{ marginBottom: "25px" }}
                    >
                      <div className="wdiget custom-text space-y-5 ">
                        <p
                          style={{
                            lineHeight: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div
                            style={{
                              marginRight: "7px",
                            }}
                          >
                            <FiClock />
                          </div>
                          Duration - {item.duration}
                        </p>
                        <p
                          style={{
                            lineHeight: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div
                            style={{
                              marginRight: "7px",
                            }}
                          >
                            <IoIosDesktop />
                          </div>
                          Course Format - {item.format}
                        </p>
                        <h4 style={{ lineHeight: "35px" }}>$ {item.fee}</h4>
                        <Link to={"#"}>
                          <button
                            className="btn px-8 py-[11px] mt-4 btn-primary text-center"
                            style={{ width: "100%" }}
                          >
                            Apply Now
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
};

export default Program7;
