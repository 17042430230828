import React from "react";

const Popup = (props) => {
  return props.trigger ? (
    <div className="modalVideo">
      <div className="modalOverlay"></div>
      <div className="center_modal">{props.children}</div>
      <div className="close" onClick={() => props.setTrigger(false)}>
        <i class="fa-solid fa-xmark"></i>
      </div>
    </div>
  ) : (
    ""
  );
};

export default Popup;
