import React from "react";
import hero from "../assets/images/Images/hero.png";
import profile1 from "../assets/images/Images/profile1.png";
import hero3 from "../assets/images/Images/hero3.png";
import Loader from "./Loader";

const Banner = ({ loading }) => {
  return (
    <>
      {loading && <Loader />}
      <section
        className={`xl:min-h-screen bg-[url('../images/banner/2.png')] bg-cover bg-no-repeat bg-center overflow-hidden`}
      >
        {/* paddingBot */}
        <div className="container relative bannerMain">
          <div className="max-w-[570px] xl:pt-[146px] bannerPadding28 md:pb-20 pb-14 xl:pb-40 space-y-8 bannerData">
            <h5>We Help Executives, Professionals & Entrepreneurs </h5>
            <h3>LIVE THEIR DREAM LIFE</h3>
            <h5>Without Regrets</h5>
            <h4>THROUGH DREAM LIFE MANIFESTER</h4>
          </div>
          <div className="bannerImg">
            <img
              src={hero3}
              alt="manOne"
              style={{
                width: "100px !important",
                height: "100px !important",
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
