import React from "react";
import { Link } from "react-router-dom";

const Publications = () => {
  return (
    <div
      className="about-area section-padding"
      style={{ position: "relative" }}
    >
      <h4
        style={{
          textAlign: "center",
          color: "#000",
        }}
      >
        Publications
      </h4>
      <div className="container pt-5 pb-6">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px] pt-1">
          <div
            className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
            data-aos="zoom-in-down"
            data-aos-duration="1800"
          >
            <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
              <Link to="#">
                <img src="" alt="" className=" w-full h-full rounded-t-[8px]" />
              </Link>
            </div>
            <div className="course-content p-6">
              <Link to="#">
                <h5 className="text-[22px] mb-3 font-bold">Wrote a column</h5>
                <p
                  style={{
                    color: "#000",
                    fontWeight: "600",
                    fontSize: "17px",
                    textAlign: "justify",
                  }}
                >
                  “Vimya vishayi Sarva kahi – Everything about Insurance” in
                  Sakaal newspaper, Pune in 2011
                </p>
              </Link>
            </div>
          </div>
          <div
            className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
            data-aos="zoom-in-down"
            data-aos-duration="1800"
          >
            <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
              <Link to="#">
                <img src="" alt="" className=" w-full h-full rounded-t-[8px]" />
              </Link>
            </div>
            <div className="course-content p-6">
              <Link to="#">
                <h5 className="text-[22px] mb-3 font-bold">
                  Manage Your Money NOW
                </h5>
                <p
                  style={{
                    color: "#000",
                    fontWeight: "600",
                    fontSize: "17px",
                    textAlign: "justify",
                  }}
                >
                  The Expertise – September 2016 (PDF available)
                </p>
              </Link>
            </div>
          </div>
          <div
            className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
            data-aos="zoom-in-down"
            data-aos-duration="1800"
          >
            <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
              <Link to="#">
                <img src="" alt="" className=" w-full h-full rounded-t-[8px]" />
              </Link>
            </div>
            <div className="course-content p-6">
              <Link to="#">
                <h5 className="text-[22px] mb-3 font-bold">Co-author</h5>
                <p
                  style={{
                    color: "#000",
                    fontWeight: "600",
                    fontSize: "17px",
                    textAlign: "left",
                  }}
                >
                  Role of financial analytics in managing personal investment
                  patterns of individuals along with Ms. Anita Ghatnekar, Ph.D.
                  Student of Pacific University, Udaipur (article available)
                </p>
              </Link>
            </div>
          </div>
          <div
            className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
            data-aos="zoom-in-down"
            data-aos-duration="1800"
          >
            <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
              <Link to="#">
                <img src="" alt="" className=" w-full h-full rounded-t-[8px]" />
              </Link>
            </div>
            <div className="course-content p-6">
              <Link to="#">
                <h5 className="text-[22px] mb-3 font-bold">
                  ‘You are Richer Than You Think’{" "}
                </h5>
                <p
                  style={{
                    color: "#000",
                    fontWeight: "600",
                    fontSize: "17px",
                    textAlign: "left",
                  }}
                >
                  Article published in BUSIN – In-house magazine of D.Y.Patil
                  Business School, Pune
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publications;
