import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useAPI } from "../Context/apiContext";
import MobileMenu from "./MobileMenu";
import axios from "axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdArrowForwardIos } from "react-icons/md";
import swal from "sweetalert";
import Aos from "aos";

const Header = ({
  show,
  setShow,
  showNews,
  setShowNews,
  disable,
  setDisable,
}) => {
  const [activeMobileMenu, setActiveMobileMenu] = useState(false);
  const [toggleButton, setToggleButton] = useState(1);
  const [mediaDropdown, setMediaDropdown] = useState(false);
  const [eventDropdwon, setEventDropdown] = useState(false);
  const [hireDropdown, setHireDropdown] = useState(false);

  const toggleTab = (index) => {
    setToggleButton(index);
  };

  const { logoData } = useAPI();

  const [nameEnq, setNameEnq] = useState("");
  const [phoneEnq, setPhoneEnq] = useState("");
  const [emailEnq, setEmailEnq] = useState("");

  const nameEngChange = (evt) => {
    setNameEnq(evt.target.value);
  };
  const namePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPhoneEnq(value);
  };
  const emailEnqChange = (evt) => {
    setEmailEnq(evt.target.value);
  };

  const isNameValid = nameEnq !== "";
  const isNumberValid = phoneEnq !== "";
  const isEmailValid = emailEnq !== "";

  const [errorName, setErrorName] = useState(false);
  const [errorNumber, setErrorNumber] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const enquiryValue = {
    name: nameEnq,
    email: emailEnq,
    mobile: phoneEnq,
  };

  const submitEnquiry = async () => {
    await axios
      .post(
        "https://applextechnologies.com/suhas_harshe/admin/api/SubscribeNewsLetter",
        enquiryValue,
        options
      )
      .then((res) => {
        if (isEmailValid == true) {
          swal({
            title: res.data.RESPONSE_MSG,
            icon: "success",
            button: "Ok",
          });
          console.log(res);
        } else {
          console.log("False");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const scrollNav = useRef(null);
  const scroollModal = useRef(null);
  useEffect(() => {
    // scrolling nav
    window.addEventListener("scroll", () => {
      let windowScroll = window.scrollY > 100;
      scrollNav.current.classList.toggle("rt-sticky-active", windowScroll);
      scrollNav.current.classList.toggle("sticky", windowScroll);
    });

    window.addEventListener("scroll", () => {
      let windowScroll = window.scrollY > 100;
      scroollModal.current.classList.toggle("stickyModal", windowScroll);
    });
  }, []);

  const [newsletter, setNewsLetter] = useState("");
  const newsLetterChange = (evt) => {
    setNewsLetter(evt.target.value);
  };
  const isNewsLetterValid = newsletter !== "";
  const [errorNewsletter, setErrorNewsletter] = useState(false);

  const newsData = {
    email: newsletter,
  };

  const subscribeNewsletter = async () => {
    await axios
      .post("/api/SubscribeNewsLetter", newsData, options)
      .then((res, e) => {
        if (isNewsLetterValid == true) {
          swal({
            title: res.data.RESPONSE_MSG,
            icon: "success",
            button: "Ok",
          });
          e.preventDefault();
          setNewsLetter("");
        } else {
          console.log("FALSE");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    Aos.init({ duration: 1000, disable: "mobile" });
  });

  return (
    <>
      <header
        className="header_bg site-header  home-one-header top-0 w-full z-[999] rt-sticky-active shadowBox"
        ref={scrollNav}
      >
        <div className="main-header py-head">
          <div className="container">
            <div className=" flex items-center justify-between">
              <Link to={"/"} className="brand-logo flex-none  md:w-auto">
                <img src={logoData.logo} alt="logo" className="logo" />
              </Link>
              <div className="flex items-center flex-1">
                <div className="flex-1 main-menu relative mr-[73px]">
                  <ul className="menu-active-classNamees">
                    <li>
                      <Link
                        to={"/"}
                        className={toggleButton === 1 ? "active_nav" : ""}
                        onClick={() => {
                          toggleTab(1);
                          setDisable(false);
                        }}
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/about"}
                        className={toggleButton === 2 ? "active_nav" : ""}
                        onClick={() => {
                          toggleTab(2);
                          setDisable(false);
                        }}
                      >
                        About
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/programs"}
                        className={toggleButton === 3 ? "active_nav" : ""}
                        onClick={() => {
                          toggleTab(3);
                          setDisable(false);
                        }}
                      >
                        Programs
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/blog"}
                        className={toggleButton === 6 ? "active_nav" : ""}
                        onClick={() => {
                          toggleTab(6);
                          setDisable(false);
                        }}
                      >
                        Blog
                      </Link>
                    </li>
                    {disable ? (
                      <li
                        onMouseEnter={() => setHireDropdown(true)}
                        onMouseLeave={() => setHireDropdown(false)}
                      >
                        <Link
                          to={"#"}
                          className={toggleButton === 7 ? "active_nav" : ""}
                        >
                          Hire Moneyogi
                        </Link>
                        {hireDropdown ? (
                          <div
                            className="hireDropdown"
                            onClick={() => {
                              toggleTab(7);
                              setDisable(true);
                            }}
                          >
                            <div className="linkDrop">
                              <Link to="/hire-moneyogi">Services</Link>
                              <Link to="#">
                                <MdArrowForwardIos className="icon" />
                              </Link>
                            </div>
                            <div
                              className="linkDrop"
                              onMouseEnter={() => setEventDropdown(true)}
                              onMouseLeave={() => setEventDropdown(false)}
                            >
                              <Link to="#">Speaking</Link>
                              <Link to="#">
                                <MdArrowForwardIos className="icon" />
                              </Link>
                              {eventDropdwon ? (
                                <div className="speakDropdown">
                                  <div className="linkDrop">
                                    <Link to="/speaking-questionnaire">
                                      Speaking Questionnaire
                                    </Link>
                                    <Link to="#">
                                      {/* <MdArrowForwardIos className="icon" /> */}
                                    </Link>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : null}
                      </li>
                    ) : (
                      <li
                        onMouseEnter={() => setHireDropdown(true)}
                        onMouseLeave={() => setHireDropdown(false)}
                      >
                        <Link
                          to={"#"}
                          className={
                            toggleButton === 7
                              ? "active_nav"
                              : "zoom-in-out-box "
                          }
                          onClick={() => {
                            toggleTab(7);
                            setDisable(true);
                          }}
                        >
                          Hire Moneyogi
                        </Link>
                        {hireDropdown ? (
                          <div
                            className="hireDropdown"
                            onClick={() => {
                              toggleTab(7);
                              setDisable(true);
                            }}
                          >
                            <div className="linkDrop">
                              <Link to="/hire-moneyogi">Services</Link>
                              <Link to="#">
                                <MdArrowForwardIos className="icon" />
                              </Link>
                            </div>
                            <div
                              className="linkDrop"
                              onMouseEnter={() => setEventDropdown(true)}
                              onMouseLeave={() => setEventDropdown(false)}
                            >
                              <Link to="#">Speaking</Link>
                              <Link to="#">
                                <MdArrowForwardIos className="icon" />
                              </Link>
                              {eventDropdwon ? (
                                <div className="speakDropdown">
                                  <div className="linkDrop">
                                    <Link to="/speaking-questionnaire">
                                      Speaking Questionnaire
                                    </Link>
                                    <Link to="#">
                                      {/* <MdArrowForwardIos className="icon" /> */}
                                    </Link>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : null}
                      </li>
                    )}
                    <li
                      onMouseEnter={() => setMediaDropdown(true)}
                      onMouseLeave={() => setMediaDropdown(false)}
                    >
                      <Link
                        to={"#"}
                        className={toggleButton === 4 ? "active_nav" : ""}
                        onClick={() => {
                          toggleTab(4);
                          setDisable(false);
                        }}
                      >
                        Media
                      </Link>
                      {mediaDropdown ? (
                        <div
                          className="mediaDropdown"
                          onClick={() => {
                            toggleTab(4);
                            setDisable(false);
                          }}
                        >
                          <div className="linkDrop">
                            <Link to="/gallery-listing">Gallery</Link>
                            <Link to="#">
                              <MdArrowForwardIos className="icon" />
                            </Link>
                          </div>
                          <div
                            className="linkDrop"
                            onMouseEnter={() => setEventDropdown(true)}
                            onMouseLeave={() => setEventDropdown(false)}
                          >
                            <Link to="#">Events</Link>
                            <Link to="#">
                              <MdArrowForwardIos className="icon" />
                            </Link>
                            {eventDropdwon ? (
                              <div className="eventDropdown">
                                <div className="linkDrop">
                                  <Link to="/past-events">Past</Link>
                                  <Link to="#">
                                    {/* <MdArrowForwardIos className="icon" /> */}
                                  </Link>
                                </div>
                                <div className="linkDrop">
                                  <Link to="/upcoming-events">Upcoming</Link>
                                  <Link to="#">
                                    {/* <MdArrowForwardIos className="icon" /> */}
                                  </Link>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </li>
                    <li>
                      <Link
                        to={"/testmonial"}
                        className={toggleButton === 5 ? "active_nav" : ""}
                        onClick={() => {
                          toggleTab(5);
                        }}
                      >
                        Success Stories
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="flex-none flex space-x-[18px]">
                  {/* <div className=" hidden lg:block">
                    <Link
                      to={"#"}
                      onClick={() => {
                        setShow(true);
                      }}
                      className="btn btn-header py-[15px] px-8"
                    >
                      Free eBook
                    </Link>
                  </div> */}
                  <div className="block lg:hidden">
                    <button
                      type="button"
                      className=" text-3xl md:w-[56px] h-10 w-10 md:h-[56px] rounded bg-[#F8F8F8] flex flex-col items-center justify-center
                                        menu-click"
                      onClick={() => setActiveMobileMenu(!activeMobileMenu)}
                    >
                      <iconify-icon
                        icon="cil:hamburger-menu"
                        rotate="180deg"
                      ></iconify-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {showNews == true ? (
        <div
          className="headerform"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="500"
          ref={scroollModal}
        >
          <AiOutlineCloseCircle
            className="-icon"
            onClick={() => setShowNews(false)}
          />
          <input
            type="text"
            placeholder="Enter Yout Name"
            required
            value={nameEnq}
            onChange={nameEngChange}
            onBlur={() => setErrorName(true)}
            style={{ marginTop: "33px" }}
          />
          <div style={{ marginBottom: "0px !important", textAlign: "start" }}>
            {errorName ? (
              isNameValid ? (
                ""
              ) : (
                <p className="errorMessage">Name Is Required</p>
              )
            ) : null}
          </div>
          <input
            type="number"
            placeholder="Enter Yout Mobile No"
            required
            value={phoneEnq}
            onChange={namePhoneChange}
            onBlur={() => setErrorNumber(true)}
            maxLength="10"
            style={{
              marginBottom: "10px",
              borderRadius: "6px",
              border: "1px solid #adadad",
            }}
          />
          {errorNumber ? (
            isNumberValid ? (
              ""
            ) : (
              <p className="errorMessage">Number Is Required</p>
            )
          ) : null}
          <input
            type="email"
            placeholder="Enter Yout Email Id"
            required
            value={emailEnq}
            onChange={emailEnqChange}
            onBlur={() => setErrorEmail(true)}
          />
          <div style={{ marginBottom: "15px" }}>
            {errorEmail ? (
              isEmailValid ? (
                ""
              ) : (
                <p className="errorMessage">Email Is Required</p>
              )
            ) : null}
          </div>
          <div className="lg:block">
            <Link
              to={"#"}
              className="btn btn-header py-[10px] px-8"
              onClick={() => {
                setErrorNewsletter(true);
                // subscribeNewsletter();
              }}
            >
              Subscribe Now
            </Link>
          </div>
        </div>
      ) : null}
      {show == true ? (
        <div
          className="headerform"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="500"
          ref={scroollModal}
        >
          <AiOutlineCloseCircle
            className="-icon"
            onClick={() => setShow(false)}
          />
          <input
            type="text"
            placeholder="Enter Yout Name"
            required
            value={nameEnq}
            onChange={nameEngChange}
            onBlur={() => setErrorName(true)}
            style={{ marginTop: "33px" }}
          />
          <div style={{ marginBottom: "0px !important", textAlign: "start" }}>
            {errorName ? (
              isNameValid ? (
                ""
              ) : (
                <p className="errorMessage">Name Is Required</p>
              )
            ) : null}
          </div>
          <input
            type="number"
            placeholder="Enter Yout Mobile No"
            required
            value={phoneEnq}
            onChange={namePhoneChange}
            onBlur={() => setErrorNumber(true)}
            maxLength="10"
            style={{
              marginBottom: "10px",
              borderRadius: "6px",
              border: "1px solid #adadad",
            }}
          />
          {errorNumber ? (
            isNumberValid ? (
              ""
            ) : (
              <p className="errorMessage">Number Is Required</p>
            )
          ) : null}
          <input
            type="email"
            placeholder="Enter Yout Email Id"
            required
            value={emailEnq}
            onChange={emailEnqChange}
            onBlur={() => setErrorEmail(true)}
          />
          <div style={{ marginBottom: "15px" }}>
            {errorEmail ? (
              isEmailValid ? (
                ""
              ) : (
                <p className="errorMessage">Email Is Required</p>
              )
            ) : null}
          </div>
          <div
            className="lg:block"
            onClick={() => {
              setErrorName(true);
              setErrorNumber(true);
              setErrorEmail(true);
              // submitEnquiry();
            }}
          >
            <Link to={"#"} className="btn btn-header py-[10px] px-8">
              Submit
            </Link>
          </div>
        </div>
      ) : null}
      {activeMobileMenu && (
        <MobileMenu
          activeMenu={activeMobileMenu}
          setActiveMenu={setActiveMobileMenu}
          setShow={setShow}
        />
      )}
    </>
  );
};

export default Header;
