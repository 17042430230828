import React from "react";
import about from "../../assets/images/Images/about.jpg";
import about1 from "../../assets/images/Images/about1.png";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <div
        className="about-area section-padding"
        style={{ position: "relative" }}
      >
        <div className="container">
          <div className="grid grid-cols-12 gap-[30px]">
            <div className="xl:col-span-5 lg:col-span-6 col-span-12">
              <img src={about} alt="" className=" block w-full aboutonePosi" />
            </div>
            <div className="xl:col-span-7 lg:col-span-6 col-span-12">
              {/* <div className="mini-title">About</div> */}
              <h4 style={{ color: "#000" }}>
                # 1 Financial Advisor among Top 8 Best Financial Advisors in
                India.
              </h4>
              <br />
              <h2 style={{ color: "#000" }}>Humble Beginning</h2>
              <br />
              <p style={{ color: "#000" }}>
                I was a regular kid born in a normal middle-class family, with
                limited opportunities. I had zero ambition. I was not interested
                in success.
              </p>
              <br />
              <p style={{ color: "#000" }}>
                I am an introvert. Still, I worked my way up in the corporate
                world for 24 years starting from being an accidental salesperson
                to becoming the Vice-President of one of the largest financial
                services groups. My corporate journey took me across the length
                & breadth of the country which allowed me to learn about sales,
                marketing, finance & business development.
              </p>
              <br />
              <p style={{ color: "#000" }}>
                My Mom was an angel for me. My world turned upside-down when my
                mother suffered a life-threatening stroke in 2009. It was so
                painful to see her lying in the hospital bed fighting for her
                life. She was subsequently paralyzed and bedridden. I never
                wanted to see her in that situation again. At that moment, I
                vowed to myself to do whatever it takes to treat her. No
                excuses.
              </p>
              <br />
              <p style={{ color: "#000" }}>
                Her sudden illness came as a rude shock which brought the
                realization of not only the frailty of life but also the
                importance of having control over your finances. I had exhausted
                all life’s savings and investments on her treatment within a
                year. I was broke.
              </p>
              <br />
              <p style={{ color: "#000" }}>
                That was very much my turning point.
              </p>
              <br />
              <p style={{ color: "#000" }}>
                Faced with the daunting task of rebuilding my financial life
                from ground zero to achieving my life goals, fulfilling my
                family responsibilities, and having a certain standard of
                living, I looked around for guidance to come out of this
                situation. I found none!
              </p>
              <br />
              <p style={{ color: "#000" }}>
                This inspired me to leave my comfortable corporate career as one
                of the top-performing employees, study to become a Financial
                Planner, and start my practice as a Money Coach in 2011. In
                spite of my best efforts, I struggled in my initial business
                ventures, going almost broke for the second time.
              </p>
              <br />
              <p style={{ color: "#000" }}>
                Driven by my purpose, I started building my businesses and
                within a decade, had established multiple successful enterprises
                in different sectors including a corporate training company, a
                financial planning company, a sales outsourcing company, a
                succession planning company, and many more.
              </p>
              <br />
              <p style={{ color: "#000" }}>
                When I look back on my entrepreneurial journey, I realize the
                crucial mistake of trying to build a business without acquiring
                the required skillset and mindset of managing money which every
                business owner must possess to become successful. I sincerely
                feel that I could have saved a huge amount of time, money, and
                effort if I had decided to engage the services of a money coach
                from the beginning.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area marginBottom" 
        style={{ position: "relative" }}>
        <div className="container">
          <div className="grid grid-cols-12 gap-[30px]">
            <div className="xl:col-span-7 lg:col-span-6 col-span-12">
              {/* <div className="mini-title">About</div> */}
              <h4 style={{ color: "#000" }}>Academic Prowess</h4>
              <p style={{ color: "#000" }}>
                I am a simple B.Sc. in Mathematics from M. S. University of
                Baroda.
              </p>
              <p style={{ color: "#000" }}>
                During my journey in the field of finance over the last 22
                years, I have acquired many certifications in the subjects of
                insurance, mutual funds, banking, stock markets, sales, training
                & coaching. I completed my International Coach Federation (ICF)
                Certified ACSTH course in 2016 from Symbiosis, U.S. I am also a
                Member Coach in the renowned International Coach Federation
                (ICF) & Certified Coaches Alliance (CCA)
              </p>
              <br />
              <h4 style={{ color: "#000" }}>
                Multiple Awards Winning Money Coach
              </h4>
              <p style={{ color: "#000" }}>
                Being a multi-faceted personality with a creative mind, I have
                designed & delivered coaching programs for executives,
                professionals, and entrepreneurs to acquire a successful money
                mindset and improve their existing finance skills. My ideas,
                tips, and strategies have helped thousands of people reach the
                next level of financial success.
              </p>
              <p style={{ color: "#000" }}>
                As a result of such incredible achievements, I have won numerous
                honors, including being nominated as the No. 1 Financial Advisor
                among the Top 8 Best Financial Advisors in India by
                ebizzing.com.
              </p>
              <p style={{ color: "#000" }}>
                Today, even though I’ve taught thousands of people, I remember
                where I started.
              </p>
              <br />
              <p style={{ color: "#000" }}>
                I am married to a Singer and a father to a Social Designer.
              </p>
              <p style={{ color: "#000" }}>
                I hope my story shows others that there are no excuses.
              </p>
              <ul style={{ color: "#000", marginTop: "15px" }}>
                <li style={{ marginBottom: "10px" }}>
                  # Founder – Financial Security Systems – Online Financial
                  Training Company
                </li>
                <li style={{ marginBottom: "10px" }}>
                  # Director – Enlightened Wealth Advisors Pvt. Ltd. – A
                  Financial Planning Company
                </li>
                <li style={{ marginBottom: "10px" }}>
                  # Director - Successful Succession - A Succession Planning
                  Company
                </li>
                <li style={{ marginBottom: "10px" }}>
                  # Co-founder – Yashallay Trainers & Consultants LLP – A Sales
                  Outsourcing Company
                </li>
                <li style={{ marginBottom: "10px" }}>
                  # The Corporate Trainers – A Corporate Training Company
                </li>
              </ul>
            </div>
            <div className="xl:col-span-5 lg:col-span-6 col-span-12">
              <img src={about1} alt="" className=" block w-full abouttwoPosi" />
            </div>
          </div>
        </div>
      </div>
      <h4
        style={{
          textAlign: "center",
          color: "#000",
        }}
        className="credentialsMargin"
      >
        Credentials
      </h4>
      <div className="container pt-5 pb-6 marginBottom">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px] pt-1">
          <div
            className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
            data-aos="zoom-in-down"
            data-aos-duration="1800"
          >
            <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
              <Link to="#">
                <img src="" alt="" className=" w-full h-full rounded-t-[8px]" />
              </Link>
            </div>
            <div className="course-content p-6">
              <Link to="#">
                <h4 className=" text-xl mb-3 font-bold">
                  # AMFI Certification (Mutual Funds)
                </h4>
              </Link>
            </div>
          </div>
          <div
            className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
            data-aos="zoom-in-down"
            data-aos-duration="1800"
          >
            <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
              <Link to="#">
                <img src="" alt="" className=" w-full h-full rounded-t-[8px]" />
              </Link>
            </div>
            <div className="course-content p-6">
              <Link to="#">
                <h4 className=" text-xl mb-3 font-bold">
                  # IRDAI Certification (Insurance)
                </h4>
              </Link>
            </div>
          </div>
          <div
            className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
            data-aos="zoom-in-down"
            data-aos-duration="1800"
          >
            <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
              <Link to="#">
                <img src="" alt="" className=" w-full h-full rounded-t-[8px]" />
              </Link>
            </div>
            <div className="course-content p-6">
              <Link to="#">
                <h4 className=" text-xl mb-3 font-bold">
                  # BSE Certificate (Shares)
                </h4>
              </Link>
            </div>
          </div>
          <div
            className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
            data-aos="zoom-in-down"
            data-aos-duration="1800"
          >
            <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
              <Link to="#">
                <img src="" alt="" className=" w-full h-full rounded-t-[8px]" />
              </Link>
            </div>
            <div className="course-content p-6">
              <Link to="#">
                <h4 className=" text-xl mb-3 font-bold">
                  # ICF Money Coach Certification (Money Coaching)
                </h4>
              </Link>
            </div>
          </div>
          <div
            className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
            data-aos="zoom-in-down"
            data-aos-duration="1800"
          >
            <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
              <Link to="#">
                <img src="" alt="" className=" w-full h-full rounded-t-[8px]" />
              </Link>
            </div>
            <div className="course-content p-6">
              <Link to="#">
                <h4 className=" text-xl mb-3 font-bold">
                  # Insurance Planning Certificate
                </h4>
              </Link>
            </div>
          </div>
          <div
            className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
            data-aos="zoom-in-down"
            data-aos-duration="1800"
          >
            <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
              <Link to="#">
                <img src="" alt="" className=" w-full h-full rounded-t-[8px]" />
              </Link>
            </div>
            <div className="course-content p-6">
              <Link to="#">
                <h4 className=" text-xl mb-3 font-bold">
                  # Investment Planning Certificate
                </h4>
              </Link>
            </div>
          </div>
          <div
            className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
            data-aos="zoom-in-down"
            data-aos-duration="1800"
          >
            <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
              <Link to="#">
                <img src="" alt="" className=" w-full h-full rounded-t-[8px]" />
              </Link>
            </div>
            <div className="course-content p-6">
              <Link to="#">
                <h4 className=" text-xl mb-3 font-bold">
                  # Retirement Planning Certificate
                </h4>
              </Link>
            </div>
          </div>
          <div
            className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
            data-aos="zoom-in-down"
            data-aos-duration="1800"
          >
            <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
              <Link to="#">
                <img src="" alt="" className=" w-full h-full rounded-t-[8px]" />
              </Link>
            </div>
            <div className="course-content p-6">
              <Link to="#">
                <h4 className=" text-xl mb-3 font-bold">
                  # Tax Planning Certificate
                </h4>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <h4
        style={{
          textAlign: "center",
          color: "#000",
        }}
      >
        Publications
      </h4>
      <div className="container pt-5 pb-6">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px] pt-1">
          <div
            className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
            data-aos="zoom-in-down"
            data-aos-duration="1800"
          >
            <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
              <Link to="#">
                <img src="" alt="" className=" w-full h-full rounded-t-[8px]" />
              </Link>
            </div>
            <div className="course-content p-6">
              <Link to="#">
                <h5 className="text-[22px] mb-3 font-bold">Wrote a column</h5>
                <p
                  style={{
                    color: "#000",
                    fontWeight: "600",
                    fontSize: "17px",
                    textAlign: "justify",
                  }}
                >
                  “Vimya vishayi Sarva kahi – Everything about Insurance” in
                  Sakaal newspaper, Pune in 2011
                </p>
              </Link>
            </div>
          </div>
          <div
            className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
            data-aos="zoom-in-down"
            data-aos-duration="1800"
          >
            <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
              <Link to="#">
                <img src="" alt="" className=" w-full h-full rounded-t-[8px]" />
              </Link>
            </div>
            <div className="course-content p-6">
              <Link to="#">
                <h5 className="text-[22px] mb-3 font-bold">
                  Manage Your Money NOW
                </h5>
                <p
                  style={{
                    color: "#000",
                    fontWeight: "600",
                    fontSize: "17px",
                    textAlign: "justify",
                  }}
                >
                  The Expertise – September 2016 (PDF available)
                </p>
              </Link>
            </div>
          </div>
          <div
            className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
            data-aos="zoom-in-down"
            data-aos-duration="1800"
          >
            <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
              <Link to="#">
                <img src="" alt="" className=" w-full h-full rounded-t-[8px]" />
              </Link>
            </div>
            <div className="course-content p-6">
              <Link to="#">
                <h5 className="text-[22px] mb-3 font-bold">Co-author</h5>
                <p
                  style={{
                    color: "#000",
                    fontWeight: "600",
                    fontSize: "17px",
                    textAlign: "left",
                  }}
                >
                  Role of financial analytics in managing personal investment
                  patterns of individuals along with Ms. Anita Ghatnekar, Ph.D.
                  Student of Pacific University, Udaipur (article available)
                </p>
              </Link>
            </div>
          </div>
          <div
            className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
            data-aos="zoom-in-down"
            data-aos-duration="1800"
          >
            <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
              <Link to="#">
                <img src="" alt="" className=" w-full h-full rounded-t-[8px]" />
              </Link>
            </div>
            <div className="course-content p-6">
              <Link to="#">
                <h5 className="text-[22px] mb-3 font-bold">
                  ‘You are Richer Than You Think’{" "}
                </h5>
                <p
                  style={{
                    color: "#000",
                    fontWeight: "600",
                    fontSize: "17px",
                    textAlign: "left",
                  }}
                >
                  Article published in BUSIN – In-house magazine of D.Y.Patil
                  Business School, Pune
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
