import { useState, useEffect } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Helmet } from "react-helmet";
import { APIContextProvider } from "./Context/apiContext";
import HomeOne from "./Components/Home/HomeOne";
import HomeThree from "./Components/Home/HomeThree";
import HomeTwo from "./Components/Home/HomeTwo";
import ErrorPage from "./Components/Pages/404Page";
import AboutOne from "./Components/Pages/AboutOne";
import AboutTwo from "./Components/Pages/AboutTwo";
import BLogStandard from "./Components/Pages/BLogStandard";
import ContactUs from "./Components/Pages/ContactUs";
import Courses from "./Components/Pages/Courses";
import CourseSideBar from "./Components/Pages/CourseSideBar";
import Event from "./Components/Pages/Event";
import EventSingle from "./Components/Pages/EventSingle";
import InstructorDetails from "./Components/Pages/InstructorDetails";
import InstructorOne from "./Components/Pages/InstructorOne";
import InstructorTwo from "./Components/Pages/InstructorTwo";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy";
import Resources from "./Components/Pages/Resources";
import SingleBlog from "./Components/Pages/SingleBlog";
import SingleCourse from "./Components/Pages/SingleCourse";
import TermsConditions from "./Components/Pages/TermsConditions";
import Thanks from "./Components/Pages/Thanks";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import TopHeader from "./Components/TopHeader";
import TestmonialPage from "./Components/Pages/TestmonialPage";
import SingleService from "./Components/Pages/SingleService";
import Program1 from "./Components/Programs/Program1";
import Program2 from "./Components/Programs/Program2";
import Program4 from "./Components/Programs/Program4";
import Program5 from "./Components/Programs/Program5";
import Program6 from "./Components/Programs/Program6";
import Program7 from "./Components/Programs/Program7";
import Program8 from "./Components/Programs/Program8";
import Hire from "./Components/Programs/Hire";
import Moneyscan from "./Components/Pages/Moneyscan";
import Quotient from "./Components/Pages/Quotient";
import Past from "./Components/Past";
import Upcoming from "./Components/Upcoming";
import Gallery from "./Components/Gallery";
import GalleryList from "./Components/GalleryList";
import EventDetail from "./Components/EventDetail";
import Speaking from "./Components/Programs/Speaking";

// export const history = createBrowserHistory({
//   basename: process.env.PUBLIC_URL,
// });

// Let's include the following elements of
// Featured In
// showcasing the below-mentioned publications and their logos:
// Outlook Money
// Sakaal
// Metro Realty
// BUSIN

function App() {
  const [contactData, setContactData] = useState([]);
  const [show, setShow] = useState(false);
  const [showNews, setShowNews] = useState(false);
  const [disable, setDisable] = useState(false);

  const getContactData = async () => {
    return await fetch("https://moneyogi.com/admin/api/GetContactDetails")
      .then((res) => res.json())
      .then((res) => {
        setContactData(res.data);
      });
  };

  useEffect(() => {
    getContactData();
  }, []);

  return (
    <>
      <APIContextProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>MoneYogi</title>
          <link rel="icon" id="faviconIcon" href={contactData.favicon} />
        </Helmet>
        <HashRouter>
          <div className="font-gilroy font-medium text-gray text-lg leading-[27px]">
            <TopHeader setShow={setShow} setShowNews={setShowNews} />
            <Header
              show={show}
              setShow={setShow}
              showNews={showNews}
              setShowNews={setShowNews}
              disable={disable}
              setDisable={setDisable}
            />
            <Routes>
              <Route path={"/"} element={<HomeOne />} />
              <Route path={"/about"} element={<AboutOne />} />
              <Route path={"/resources"} element={<Resources />} />
              <Route path={"/events"} element={<Event />} />
              <Route
                path={"/media-detail/:media_id"}
                element={<EventSingle />}
              />
              <Route path={"/programs"} element={<Courses />} />
              <Route
                path={"/service-detail/:service_id"}
                element={<SingleService />}
              />
              {/* programs  */}
              <Route path="/program-detaill/1" element={<Program1 />} />
              <Route path="/program-detaill/3" element={<Program2 />} />
              <Route path="/program-detail/4" element={<Program4 />} />
              <Route path="/program-detail/5" element={<Program5 />} />
              <Route path="/program-detail/6" element={<Program6 />} />
              <Route path="/program-detail/7" element={<Program7 />} />
              <Route path="/program-detail/8" element={<Program8 />} />

              <Route path="/hire-moneyogi" element={<Hire />} />
              <Route path="/speaking-questionnaire" element={<Speaking />} />
              <Route path="/moneyscan" element={<Moneyscan />} />
              <Route path="/money-quotient" element={<Quotient />} />
              <Route path="/past-events" element={<Past />} />
              <Route path="/upcoming-events" element={<Upcoming />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/gallery-listing" element={<GalleryList />} />
              <Route path="/event-detail" element={<EventDetail />} />

              <Route path={"/blog"} element={<BLogStandard />} />
              <Route path={"/blog-detail/:blog_id"} element={<SingleBlog />} />
              <Route path={"/testmonial"} element={<TestmonialPage />} />
              <Route path={"/contacts"} element={<ContactUs />} />
              <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
              <Route path={"/terms-conditions"} element={<TermsConditions />} />

              <Route path={"/home-two"} element={<HomeTwo />} />
              <Route
                path="/react-templates/edumim/home-three"
                element={<HomeThree />}
              />
              <Route
                path="/react-templates/edumim/about-two"
                element={<AboutTwo />}
              />
              <Route
                path="/react-templates/edumim/instructor"
                element={<InstructorOne />}
              />
              <Route
                path="/react-templates/edumim/instructor-two"
                element={<InstructorTwo />}
              />
              <Route
                path="/react-templates/edumim/instructor-details"
                element={<InstructorDetails />}
              />
              <Route
                path="/react-templates/edumim/error"
                element={<ErrorPage />}
              />
              <Route
                path="/react-templates/edumim/courses-sidebar"
                element={<CourseSideBar />}
              />
              <Route path={"/suhas_harshe/thanks"} element={<Thanks />} />

              <Route path="*" element={<ErrorPage />} />
            </Routes>
            <Footer />
          </div>
        </HashRouter>
      </APIContextProvider>
    </>
  );
}

export default App;
