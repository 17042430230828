import React, { useEffect, useState } from "react";
import { useAPI } from "../../Context/apiContext";
import swal from "sweetalert";
import axios from "axios";
import About from "../About";
import Banner from "../Banner";
import Blogs from "../Blogs";
import Courses from "../Courses";
import Feature from "../Feature";
import Testimonial from "../HomeThreeComponents/Testimonial";
import NewsLetter from "../NewsLetter";
import Topics from "../Topics";
import Brands from "../Brands";
import Publications from "../Publications";

const HomeOne = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    window.scrollTo(0, 0);
  }, []);

  const { sliderData } = useAPI();

  const [newsletter, setNewsLetter] = useState("");
  const newsLetterChange = (evt) => {
    setNewsLetter(evt.target.value);
  };
  const isNewsLetterValid = newsletter !== "";
  const [errorNewsletter, setErrorNewsletter] = useState(false);

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const newsData = {
    email: newsletter,
  };

  const subscribeNewsletter = async () => {
    await axios
      .post("/api/SubscribeNewsLetter", newsData, options)
      .then((res, e) => {
        if (isNewsLetterValid == true) {
          swal({
            title: res.data.RESPONSE_MSG,
            icon: "success",
            button: "Ok",
          });
          e.preventDefault();
          setNewsLetter("");
        } else {
          console.log("FALSE");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Banner
        loading={loading}
        newsletter={newsletter}
        setNewsLetter={setNewsLetter}
        subscribeNewsletter={subscribeNewsletter}
        sliderData={sliderData}
        errorNewsletter={errorNewsletter}
        setErrorNewsletter={setErrorNewsletter}
        newsLetterChange={newsLetterChange}
        isNewsLetterValid={isNewsLetterValid}
      />
      <Brands />
      {/* <About sliderData={sliderData} /> */}
      {/* <Topics /> */}
      <Testimonial />
      {/* <Brands section_padding_bottom={"section-padding-bottom"} /> */}
      {/* <Feature /> */}
      <Courses />
      {/* <Platform /> */}
      <NewsLetter
        newsletter={newsletter}
        setNewsLetter={setNewsLetter}
        subscribeNewsletter={subscribeNewsletter}
        errorNewsletter={errorNewsletter}
        setErrorNewsletter={setErrorNewsletter}
        newsLetterChange={newsLetterChange}
        isNewsLetterValid={isNewsLetterValid}
      />
      <Blogs />
      <Publications />
    </>
  );
};

export default HomeOne;
