import blog1 from "../assets/images/Images/Blog/blog1.jpg";
import blog2 from "../assets/images/Images/Blog/blog2.jpg";
import blog3 from "../assets/images/Images/Blog/blog3.jpg";
import blog4 from "../assets/images/Images/Blog/blog4.jpg";
import blog5 from "../assets/images/Images/Blog/blog5.jpg";
import blog6 from "../assets/images/Images/Blog/blog6.jpg";
import blog7 from "../assets/images/Images/Blog/blog7.jpg";
import blog8 from "../assets/images/Images/Blog/blog8.jpg";
import blog9 from "../assets/images/Images/Blog/blog9.jpg";
import blog10 from "../assets/images/Images/Blog/blog10.jpg";
import program from "../assets/images/Images/programs.jpg";
import service1 from "../assets/images/Images/service1.jpeg";
import service2 from "../assets/images/Images/service2.jpeg";

// Programs Data //
export const Programs = [
  {
    id: "1",
    img: service1,
    title: "MONEYSCAN",
    desc: "A quick 15-minute financial health check will provide you with a report about your current financial health...",
    link: "/program-detaill/1",
  },
  // MONEY SUCCESS BLUEPRINT 
  // A 5-hour virtual workshop where you will learn the basics of personal finance set a financial goal...
  {
    id: "2",
    img: service2,
    title: "DREAM LIFE MANIFESTER",
    desc: "Get coached one-to-one by MoneYogi Suhas Harshe himself. This personal guidance coupled...",
    link: "/program-detail/8",
  },
  {
    id: "3",
    img: program,
    title: "NEW NAME",
    desc: "A 3-day virtual workshop designed to teach you the step-by-step process of creating your own money...",
    link: "/program-detaill/3",
  },
  {
    id: "4",
    img: program,
    title: "NEW NAME – Corporate Workshop – Middle Management",
    desc: "A one-day workshop on learning the basics of money management that includes...",
    link: "/program-detail/4",
  },
  {
    id: "5",
    img: program,
    title: "MONEY SHAASTRA - Corporate Workshop – Top Management",
    desc: "A unique 4-hour seminar designed especially for top management of companies that...",
    link: "/program-detail/5",
  },
  {
    id: "6",
    img: program,
    title: "FINANSENSE / SIMPLIFINANCE",
    desc: "A one-day workshop on making finance sensible for any non-finance executive...",
    link: "/program-detail/6",
  },
  {
    id: "7",
    img: program,
    title: "MONEY HEXAGON",
    desc: "A month-long one-to-one coaching program designed to take you through the six-stage framework...",
    link: "/program-detail/7",
  },
  {
    id: "8",
    img: program,
    title: "DREAM LIFE MANIFESTER",
    desc: "",
    link: "/program-detail/8",
  },
];

// Blogs Data //
export const BlogsData = [
  {
    id: "1",
    img: blog1,
    title: "Create magic from your salary increment !!!",
    date: "Feb 16, 2023",
    link: "#",
  },
  {
    id: "2",
    img: blog2,
    title: "7 Common Mistakes that can Ruin your Finances",
    date: "Mar 02, 2023",
    link: "#",
  },
  {
    id: "3",
    img: blog3,
    title: "5 Ways to Master Money for an Earning Woman",
    date: "Mar 02, 2023",
    link: "#",
  },
  {
    id: "4",
    img: blog4,
    title: "What’s Your Money Mindset?",
    date: "Apr 29, 2023",
    link: "#",
  },
  {
    id: "5",
    img: blog5,
    title: "Money Ka Doctor",
    date: "Apr 29, 2023",
    link: "#",
  },
  {
    id: "6",
    img: blog6,
    title: "Where’s the Budget in the Budget?",
    date: "Apr 29, 2023",
    link: "#",
  },
  {
    id: "7",
    img: blog7,
    title: "Is Money your Friend?",
    date: "Apr 29, 2023",
    link: "#",
  },
  {
    id: "8",
    img: blog8,
    title: "What does ‘FREEDOM’ mean to You?",
    date: "Apr 29, 2023",
    link: "#",
  },
  {
    id: "9",
    img: blog9,
    title: "Which is the “Best” investment?",
    date: "Apr 29, 2023",
    link: "#",
  },
  {
    id: "10",
    img: blog10,
    title: "Build Your “Money Immunity”!",
    date: "Apr 29, 2023",
    link: "#",
  },
];

export const ProgramData1 = [
  {
    id: "1",
    img: "",
    title: "MONEY SUCCESS BLUEPRINT",
    desc: "A 5-hour virtual workshop where you will learn the basics of personal finance set a financial goal for the next 90 days, know about skills required to become better at managing finances, become aware of the roadblocks that prevent you from becoming rich, and create an action plan to move towards achieving your financial goals.",
    fee: "47",
    format: "Virtual",
    duration: "5 hours",
  },
];

export const ProgramData2 = [
  {
    id: "1",
    img: "",
    title: "NEW NAME",
    desc: "A 5-hour virtual workshop where you will learn the basics of personal finance set a financial goal for the next 90 days, know about skills required to become better at managing finances, become aware of the roadblocks that prevent you from becoming rich, and create an action plan to move towards achieving your financial goals.",
    fee: "197",
    format: "Virtual",
    duration: "3 days",
  },
];

export const ProgramData4 = [
  {
    id: "1",
    img: "",
    title: "NEW NAME – Corporate Workshop – Middle Management",
    desc: "A one-day workshop on learning the basics of money management that includes how to plan for your future, changing your beliefs about money, know the six roadblocks that prevent you from becoming rich, important financial ratios that impact your finances, common money mistakes to avoid, and the golden rules of investing. ",
    fee: "597",
    format: "In-person",
    duration: "1 Day",
  },
];

export const ProgramData5 = [
  {
    id: "1",
    img: "",
    title: "MONEY SHAASTRA - Corporate Workshop – Top Management",
    desc: "A unique 4-hour seminar designed especially for top management of companies that earn handsome incomes but still struggle to live the life of their choice. Learn the secrets that will help you overcome the challenges of unwanted financial stress, prevent mental exhaustion, appreciate happy relationships, enjoy good health and maintain a work-life balance successfully.",
    fee: "1,249",
    format: "In-person",
    duration: "4 hours",
  },
];

export const ProgramData6 = [
  {
    id: "1",
    img: "",
    title: "FINANSENSE / SIMPLIFINANCE",
    desc: "A one-day workshop on making finance sensible for any non-finance executive by de-cluttering and simplifying the financial terms so that he can understand them and use them in their decision-making process in their professional and personal life.",
    fee: "1,249",
    format: "Virtual/In-person ",
    duration: "1 day",
  },
];

export const ProgramData7 = [
  {
    id: "1",
    img: "",
    title: "MONEY HEXAGON",
    desc: "A month-long one-to-one coaching program designed to take you through the six-stage framework of the financial results you wish to achieve over your lifetime, take cognizance of your current financial situation, change your mindset about money, prepare your financial plan of action, build your customized money system, and measure your progress towards achieving your goals regularly. ",
    fee: "497",
    format: "Virtual/In-person ",
    duration: "1 month",
  },
];

export const ProgramData8 = [
  {
    id: "1",
    img: "",
    title: "DREAM LIFE MANIFESTER ",
    desc: "Get coached one-to-one by MoneYogi Suhas Harshe himself. This personal guidance coupled with his vast knowledge and rich experience will transform your life. In a span of 3-months over 13 powerful sessions, you will see your total makeover from someone having a lot of stress, worried about your future, feeling exhausted all the time from fulfilling your personal and professional commitments, having no time for self or family, struggling to maintain the work-life balance, facing health issues and experiencing strained relationships; to living your dream life without any regrets, fulfilling your life purpose, and enjoying peace of mind.     ",
    fee: "4997",
    format: "Virtual/In-person ",
    duration: "3 months",
  },
];
