import React, { useEffect } from "react";
import { MdDateRange } from "react-icons/md";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const GalleryList = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MoneYogi - Gallery</title>
      </Helmet>
      <div className="container p-10">
        <div className="galleryListing">
          <Link to="/gallery">
            <div className="listingCard bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm">
              <div className="listImgGrid">
                <div>
                  <img
                    src="https://plus.unsplash.com/premium_photo-1661863445750-544120cc1b50?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=869&q=80"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="https://images.unsplash.com/photo-1551415923-a2297c7fda79?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1032&q=80"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="https://images.unsplash.com/photo-1551005916-83ed42ca6b49?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="https://images.unsplash.com/photo-1535752385016-16aa049b6a8d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1523&q=80"
                    alt=""
                  />
                </div>
              </div>
              <h4>Gallery Name</h4>
              <p style={{ color: "#000" }}>
                <MdDateRange className="iconDate" /> 10/ 8/ 2023
              </p>
            </div>
          </Link>
          <Link to="/gallery">
            <div className="listingCard bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm">
              <div className="listImgGrid">
                <div>
                  <img
                    src="https://plus.unsplash.com/premium_photo-1661863445750-544120cc1b50?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=869&q=80"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="https://images.unsplash.com/photo-1551415923-a2297c7fda79?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1032&q=80"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="https://images.unsplash.com/photo-1551005916-83ed42ca6b49?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="https://images.unsplash.com/photo-1535752385016-16aa049b6a8d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1523&q=80"
                    alt=""
                  />
                </div>
              </div>
              <h4>Gallery Name</h4>
              <p style={{ color: "#000" }}>
                <MdDateRange className="iconDate" /> 10/ 8/ 2023
              </p>
            </div>
          </Link>
          <Link to="/gallery">
            <div className="listingCard bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm">
              <div className="listImgGrid">
                <div>
                  <img
                    src="https://plus.unsplash.com/premium_photo-1661863445750-544120cc1b50?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=869&q=80"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="https://images.unsplash.com/photo-1551415923-a2297c7fda79?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1032&q=80"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="https://images.unsplash.com/photo-1551005916-83ed42ca6b49?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="https://images.unsplash.com/photo-1535752385016-16aa049b6a8d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1523&q=80"
                    alt=""
                  />
                </div>
              </div>
              <h4>Gallery Name</h4>
              <p style={{ color: "#000" }}>
                <MdDateRange className="iconDate" /> 10/ 8/ 2023
              </p>
            </div>
          </Link>
          <Link to="/gallery">
            <div className="listingCard bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm">
              <div className="listImgGrid">
                <div>
                  <img
                    src="https://plus.unsplash.com/premium_photo-1661863445750-544120cc1b50?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=869&q=80"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="https://images.unsplash.com/photo-1551415923-a2297c7fda79?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1032&q=80"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="https://images.unsplash.com/photo-1551005916-83ed42ca6b49?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="https://images.unsplash.com/photo-1535752385016-16aa049b6a8d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1523&q=80"
                    alt=""
                  />
                </div>
              </div>
              <h4>Gallery Name</h4>
              <p style={{ color: "#000" }}>
                <MdDateRange className="iconDate" /> 10/ 8/ 2023
              </p>
            </div>
          </Link>
          <Link to="/gallery">
            <div className="listingCard bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm">
              <div className="listImgGrid">
                <div>
                  <img
                    src="https://plus.unsplash.com/premium_photo-1661863445750-544120cc1b50?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=869&q=80"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="https://images.unsplash.com/photo-1551415923-a2297c7fda79?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1032&q=80"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="https://images.unsplash.com/photo-1551005916-83ed42ca6b49?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="https://images.unsplash.com/photo-1535752385016-16aa049b6a8d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1523&q=80"
                    alt=""
                  />
                </div>
              </div>
              <h4>Gallery Name</h4>
              <p style={{ color: "#000" }}>
                <MdDateRange className="iconDate" /> 10/ 8/ 2023
              </p>
            </div>
          </Link>
          <Link to="/gallery">
            <div className="listingCard bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm">
              <div className="listImgGrid">
                <div>
                  <img
                    src="https://plus.unsplash.com/premium_photo-1661863445750-544120cc1b50?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=869&q=80"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="https://images.unsplash.com/photo-1551415923-a2297c7fda79?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1032&q=80"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="https://images.unsplash.com/photo-1551005916-83ed42ca6b49?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="https://images.unsplash.com/photo-1535752385016-16aa049b6a8d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1523&q=80"
                    alt=""
                  />
                </div>
              </div>
              <h4>Gallery Name</h4>
              <p style={{ color: "#000" }}>
                <MdDateRange className="iconDate" /> 10/ 8/ 2023
              </p>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default GalleryList;
