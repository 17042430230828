import { Disclosure, Tab } from "@headlessui/react";
import React, { Fragment, useState, useEffect, useReducer } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { camera, file2, play, thumb, web, clockIcon } from "../constant/images";
import { useAPI } from "../Context/apiContext";

const ServiceDetails = () => {
  const [serviceData, setServiceData] = useState([]);

  // Force Update State
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const { servicesData } = useAPI();

  const { service_id } = useParams();
  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const serviceValue = {
    service_id: service_id,
  };
  const getServiceData = async () => {
    await axios
      .post("/api/GetServiceDetail", serviceValue, options)
      .then((res) => {
        setServiceData(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getServiceData();
  }, [reducerValue]);

  return (
    <>
      <div className="nav-tab-wrapper tabs  section-padding">
        {serviceData.map((item) => {
          return (
            <div className="container rel">
              <div className="grid grid-cols-12 gap-[30px]">
                <div className="lg:col-span-8 col-span-12">
                  <div className="single-course-details">
                    <div className="xl:h-[470px] h-[350px] mb-10 course-main-thumb">
                      <img
                        src={item.image}
                        alt=""
                        className=" rounded-md object-fut w-full h-full block"
                      />
                    </div>
                    <div className=" mb-6"></div>
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                    <div
                      className="author-meta mt-6 sm:flex  lg:space-x-16 sm:space-x-5 space-y-5 
               sm:space-y-0 items-center"
                    ></div>
                  </div>
                </div>
                <div className="lg:col-span-4 col-span-12 widgetPosition">
                  {servicesData.map((item, index) => {
                    const noTagsDesc = item.description.replace(
                      /<\/?[^>]+(>|$)/g,
                      ""
                    );
                    return (
                      <div>
                        {item.id === service_id ? null : (
                          <div
                            className="sidebarWrapper space-y-[30px]"
                            style={{ marginBottom: "25px" }}
                          >
                            <div className="wdiget custom-text space-y-5 ">
                              <div key={item.id}>
                                <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
                                  <Link
                                    to={`/service-detail/${item.id}`}
                                    onClick={() => forceUpdate()}
                                  >
                                    <img
                                      src={item.image}
                                      alt=""
                                      className=" w-full h-full rounded-t-[8px]"
                                    />
                                  </Link>
                                </div>
                                <div
                                  className="course-content"
                                  onClick={() => forceUpdate()}
                                >
                                  <Link to={`/service-detail/${item.id}`}>
                                    <h4 className=" text-xl mb-3 font-bold">
                                      {item.title}
                                    </h4>
                                    <p>{noTagsDesc.slice(0, 82)}...</p>
                                  </Link>
                                  <Link to={`/service-detail/${item.id}`}>
                                    <button className="btn px-8 py-[11px] mt-4 btn-primary text-center">
                                      Read More
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ServiceDetails;
