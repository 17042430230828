import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Tab } from "@headlessui/react";
import Aos from "aos";
import { calender } from "../../constant/images";
import { e1, e2, e3, e4, e5, e6, e7, e8, e9 } from "../../constant/images";
import PageBanner from "../PageBanner";
import Popup from "../Popup";
import Loader from "../Loader";
import { useAPI } from "../../Context/apiContext";

const BLogStandard = () => {
  const [ButtonPopup, setButtonPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const { blogsData, videoData, podcastsData } = useAPI();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {loading && <Loader />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>MoneYogi - Blogs</title>
      </Helmet>
      {/* <PageBanner title={"Blog Standard"} pageName={"Blog Standard"} /> */}
      <div className="nav-tab-wrapper tabs section-padding">
        <div className="container">
          <Tab.Group>
            <div className="nav-tab-wrapper tabCenter">
              <Tab.List
                as="ul"
                id="tabs-nav"
                className="course-tab blogTab mb-8"
              >
                <Tab
                  as="li"
                  className={({ selected }) => (selected ? "active" : null)}
                  style={{cursor: "pointer"}}
                >
                  <a style={{ textAlign: "center", fontSize: "20px" }}>Video</a>
                </Tab>
                <Tab
                  as="li"
                  className={({ selected }) => (selected ? "active" : null)}
                  style={{cursor: "pointer"}}
                >
                  <a style={{ textAlign: "center", fontSize: "20px" }}>Blogs</a>
                </Tab>
                <Tab
                  as="li"
                  className={({ selected }) => (selected ? "active" : null)}
                  style={{cursor: "pointer"}}
                >
                  <a style={{ textAlign: "center", fontSize: "20px" }}>
                    Podcasts
                  </a>
                </Tab>
              </Tab.List>
            </div>
            <Tab.Panels id="tabs-content">
              <Tab.Panel id="tab1" className="tab-content">
                <div className="grid  lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
                  {videoData.map((item, index) => {
                    return (
                      <>
                        <div
                          key={index}
                          data-aos="zoom-in-down"
                          data-aos-duration="1800"
                        >
                          <div className=" bg-white shadow-box5 rounded-[8px] transition duration-100 hover:shadow-box3">
                            <div className="course-thumb h-[297px] rounded-t-[8px]  relative">
                              <img
                                src={item.image}
                                alt=""
                                className=" w-full h-full object-cover rounded-t-[8px]"
                              />
                              <i
                                class="fa-solid fa-play video"
                                onClick={() => setButtonPopup(index)}
                              ></i>
                            </div>
                            <div className="course-content p-8">
                              <h4 className=" text-xl mb-5 font-bold">
                                <p
                                  className="tabs hover:text-primary transition duration-150"
                                  onClick={() => setButtonPopup(index)}
                                >
                                  {item.title}
                                </p>
                              </h4>
                            </div>
                          </div>
                        </div>
                        <Popup
                          trigger={ButtonPopup === index}
                          setTrigger={setButtonPopup}
                        >
                          <iframe src={item.link} frameborder="0"></iframe>
                        </Popup>
                      </>
                    );
                  })}
                </div>
              </Tab.Panel>
              <Tab.Panel id="tab2" className="tab-content">
                <div className="grid">
                  <div className="lg:col-span-8 col-span-1">
                    <div className="grid  lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
                      {blogsData.map((item, index) => {
                        return (
                          <div
                            className=" bg-white shadow-box12 rounded-[8px] transition duration-100 hover:shadow-box13"
                            key={index}
                            data-aos="zoom-in-down"
                            data-aos-duration="1800"
                          >
                            <div className="course-thumb h-[297px] rounded-t-[8px]  relative">
                              <img
                                src={item.image}
                                alt=""
                                className=" w-full h-full object-cover rounded-t-[8px]"
                              />
                            </div>
                            <div className="course-content p-8">
                              <h3
                                className="mb-4 mt-4"
                                style={{ fontSize: "27px", lineHeight: "30px" }}
                              >
                                <Link
                                  to={`/blog-detail/${item.id}`}
                                  className=" hover:text-primary transition duration-150"
                                >
                                  {item.title}
                                </Link>
                              </h3>
                              <div className="flex   lg:space-x-10 space-x-5 mt-6">
                                <a className=" flex items-center space-x-2">
                                  <img src={calender} alt="" />
                                  <span>{item.date}</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="lg:col-span-4 col-span-12">
                    <div className="sidebarWrapper space-y-[30px]"></div>
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel id="tab3" className="tab-content">
                <div className="container pb-8">
                  <center>
                    <div className="hero-text">
                      <h1>Coming Soon</h1>
                      <br />
                      <Link className="back-home" to={"/"}>
                        Back To Home
                      </Link>
                    </div>
                  </center>
                </div>
                {/* <div className="grid  lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
                  {podcastsData.map((item, index) => {
                    return (
                      <div
                        className=" bg-white shadow-box5 rounded-[8px] transition duration-100 hover:shadow-box3"
                        key={index}
                        data-aos="zoom-in-down"
                        data-aos-duration="1800"
                      >
                        <div className="course-thumb h-[297px] rounded-t-[8px]  relative">
                          <img
                            src={item.image}
                            alt=""
                            className=" w-full h-full object-cover rounded-t-[8px]"
                          />
                        </div>
                        <div className="course-content p-8">
                          <div className="flexPlay">
                            <i class="fa-solid fa-play play"></i>
                            <h4 className=" text-xl mb-5 font-bold">
                              <a
                                href={item.link}
                                target="_blank"
                                rel="noreferrer"
                                className=" hover:text-primary transition duration-150"
                              >
                                {item.title}
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div> */}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </>
  );
};

export default BLogStandard;
