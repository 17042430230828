import React, { useEffect } from "react";
import { Tab } from "@headlessui/react";
import { Link } from "react-router-dom";
import Aos from "aos";
import { Programs } from "../Context/Data";
import { useAPI } from "../Context/apiContext";

const FilteredCourse = ({ classNameForTabOne }) => {
  const { servicesData } = useAPI();

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      <Tab.Group className="section-padding">
        <Tab.Panels as="div" id="tabs-content">
          <Tab.Panel as="div" id="tab1" className="tab-content">
            <h4
              className="p-5 loraFont"
              style={{
                textAlign: "center",
                color: "#000",
              }}
            >
              THESE PROGRAMS CAN CHANGE YOUR{" "}
              <span className="shape-bg">LIFE FOREVER</span>
            </h4>
            <div className={classNameForTabOne}>
              {/* {servicesData.slice(0, 3).map((item, index) => {
                const noTagsDesc = item.description.replace(
                  /<\/?[^>]+(>|$)/g,
                  ""
                );
                return (
                  <div
                    className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
                    key={index}
                  >
                    <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
                      <Link to={`/service-detail/${item.id}`}>
                        <img
                          src={item.image}
                          alt=""
                          className=" w-full h-full rounded-t-[8px]"
                        />
                      </Link>
                    </div>
                    <div className="course-content p-8">
                      <Link to={`/service-detail/${item.id}`}>
                        <h4 className=" text-xl mb-3 font-bold">
                          {item.title}
                        </h4>
                        <p>{noTagsDesc.slice(0, 82)}...</p>
                      </Link>
                      <Link to={`/service-detail/${item.id}`}>
                        <button className="btn px-8 py-[11px] mt-4 btn-primary text-center">
                          Read More
                        </button>
                      </Link>
                    </div>
                  </div>
                );
              })} */}
              {Programs.slice(0, 3).map((item) => {
                return (
                  <div
                    className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm borderCard"
                    data-aos="zoom-in-down"
                    data-aos-duration="1800"
                  >
                    <div className="course-thumb h-[248px] rounded-t-[8px]  relative bottomCard">
                      <Link to={item.link}>
                        <img
                          src={item.img}
                          alt=""
                          className="courseFull h-full rounded-t-[8px]"
                        />
                      </Link>
                    </div>
                    <div className="course-content p-8">
                      <Link to={item.link}>
                        <h4 className=" text-xl mb-3 font-bold">
                          {item.title}
                        </h4>
                        <p>{item.desc}</p>
                      </Link>
                      <Link to={item.link}>
                        <button className="btn px-8 py-[11px] mt-4 btn-primary text-center">
                          Read More
                        </button>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  );
};

export default FilteredCourse;
