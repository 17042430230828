import React, { useState, useEffect } from "react";
import Loader from "../Loader";

const TermsConditions = () => {
  const [termsData, setTermsData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    window.scrollTo(0, 0);
  }, []);

  const getTermsData = async () => {
    return await fetch("https://moneyogi.com/admin/api/GetTermsConditions")
      .then((res) => res.json())
      .then((res) => {
        setTermsData(res.data);
      });
  };

  useEffect(() => {
    getTermsData();
  }, []);

  return (
    <div>
      {loading && <Loader />}
      {/* <PageBanner title={"Tearm & Condition"} pageTitle="Tearm & Condition" /> */}
      <div className="container section-padding pPage">
        <div className="p-6">
          <h2>{termsData.title}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: termsData.description,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
