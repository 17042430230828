import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Moneyscan = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container pb-8">
      <center>
        <div className="hero-text">
          <h1>Coming Soon</h1>
          <br />
          <Link className="back-home" to={"/"}>
            Back To Home
          </Link>
        </div>
      </center>
    </div>
  );
};

export default Moneyscan;
