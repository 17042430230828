import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import NextArrwo from "../Components/NextArrwo";
import PrevArrow from "../Components/PrevArrow";
// import "lightgallery/css/lightgallery.css";
// import "lightgallery/css/lg-zoom.css";
// import "lightgallery/css/lg-thumbnail.css";
// import "lightgallery/css/lg-autoplay.css";
// import "lightgallery/css/lg-share.css";
// import "lightgallery/css/lg-rotate.css";

// // import plugins if you need
// import lgThumbnail from "lightgallery/plugins/thumbnail";
// import lgZoom from "lightgallery/plugins/zoom";
// import lgAutoplay from "lightgallery/plugins/autoplay";
// import lgVideo from "lightgallery/plugins/video";
// import lgShare from "lightgallery/plugins/share";
// import lgRotate from "lightgallery/plugins/rotate";

// import LightGallery from "lightgallery/react/Lightgallery.es5";

const EventDetails = () => {
  const [eventData, setEventData] = useState([]);
  const [clickPopup, setClickPopup] = useState(false);

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const settingsAsNavFor = {
    dots: false,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: false,
    nextArrow: <NextArrwo />,
    prevArrow: <PrevArrow />,
  };

  const { media_id } = useParams();

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const eventValue = {
    gallery_id: media_id,
  };

  const getEventData = async () => {
    await axios
      .post(
        "https://moneyogi.com/admin/api/GetGalleryDetail",
        eventValue,
        options
      )
      .then((res) => {
        setEventData(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getEventData();
  }, []);

  // useEffect(() => {
  //   if (document.getElementById("timer")) {
  //     var countDownDate = new Date("Jan 5, 2024 15:37:25").getTime(); // Update the count down every 1 second

  //     var x = setInterval(function () {
  //       // Get today's date and time
  //       var now = new Date().getTime(); // Find the distance between now and the count down date

  //       var distance = countDownDate - now; // Time calculations for days, hours, minutes and seconds

  //       var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //       var hours = Math.floor(
  //         (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //       );
  //       var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //       var seconds = Math.floor((distance % (1000 * 60)) / 1000); // Display the result in the element with id="demo"

  //       document.getElementById("timer").innerHTML =
  //         "<div class='text-[44px] font-bold'>" +
  //         days +
  //         "<div class=' text-lg font-medium mt-2 capitalize'>days</div></div>" +
  //         "<div class='text-[44px] font-bold'>" +
  //         hours +
  //         "<div class='text-lg font-medium mt-2 capitalize'>hours</div></div>" +
  //         "<div class='text-[44px] font-bold'>" +
  //         minutes +
  //         "<div class='text-lg font-medium mt-2 capitalize'>minutes</div></div>" +
  //         "<div class='text-[44px] font-bold'>" +
  //         seconds +
  //         "<div class='text-lg font-medium mt-2 capitalize'>seconds</div></div>"; // If the count down is finished, write some text

  //       if (distance < 0) {
  //         clearInterval(x);
  //         document.getElementById("timer").innerHTML = "EXPIRED";
  //       }
  //     }, 1000);
  //   }
  // }, []);

  return (
    <div>
      {eventData.map((item) => {
        return (
          <>
            {/* <PageBanner title={item.title} pageTitle={"Events"} /> */}
            <div className="nav-tab-wrapper tabs section-padding">
              <div className="container">
                {/* <img
                  src={item.image}
                  alt=""
                  className=" lg:mb-10 mb-6 block w-full"
                /> */}
                <div className="grid grid-cols-1 gap-[30px]">
                  <div className="lg:col-span-8 col-span-12">
                    {/* <h3>{item.title}</h3>
                    <p>{item.date}</p> */}
                    <div
                      className="my-4"
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    />
                    {/* <div className="flexEvent">
                      {item.files.map((items, index) => {
                        return (
                          <LightGallery
                            speed={200}
                            plugins={[
                              lgThumbnail,
                              lgZoom,
                              lgShare,
                              lgRotate,
                              lgVideo,
                              lgAutoplay,
                            ]}
                          >
                            <img
                              id={index}
                              key={index}
                              src={items}
                              alt=""
                              onClick={() => {
                                setClickPopup(true);
                              }}
                            />
                          </LightGallery>
                        );
                      })}
                    </div> */}
                    <div className="flexEvent">
                      {item.files.map((items, index) => {
                        return (
                          <>
                            <img
                              key={index}
                              src={items}
                              alt=""
                              onClick={() => {
                                setClickPopup(true);
                              }}
                            />
                          </>
                        );
                      })}
                    </div>
                  </div>
                  {clickPopup === true ? (
                    <div className="fullScreenContainer">
                      <div className="fullScreenCard">
                        <div className="overlay"></div>
                        <div className="card">
                          <Slider
                            className="slider_container"
                            asNavFor={nav2}
                            ref={(slider1) => setNav1(slider1)}
                            {...settingsAsNavFor}
                          >
                            {item.files.map((item) => {
                              return (
                                <>
                                  <div className="slider_img">
                                    <img src={item} alt="" />
                                  </div>
                                </>
                              );
                            })}
                          </Slider>
                        </div>
                        <i
                          class="fa-solid fa-xmark cancel_modal"
                          onClick={() => setClickPopup(false)}
                        ></i>
                      </div>
                      {/* <div className="lg:col-span-4 col-span-12 relative lg:-mt-20">
                    <div className="sidebarWrapper max-w-[90%] mx-auto space-y-[30px]">
                      <div className="wdiget custom-text space-y-5 ">
                        <h4 className=" widget-title">Event Details</h4>
                        <ul className="list space-y-6  ">
                          <li className=" flex space-x-3 ">
                            <div className="flex-1 space-x-3 flex">
                              <img
                                src={circleClock}
                                alt=""
                                className="mLeft1 mRight"
                              />
                              <div>{item.time}</div>
                            </div>
                          </li>

                          <li className=" flex space-x-3 ">
                            <div className="flex-1 space-x-3 flex">
                              <img
                                src={calender}
                                alt=""
                                className="mLeft mRight1"
                              />
                              <div>{item.date}</div>
                            </div>
                          </li>

                          <li className=" flex space-x-3 ">
                            <div className="flex-1 space-x-3 flex">
                              <img src={map} alt="" className="iconSize" />
                              <div>{item.venue}</div>
                            </div>
                          </li>

                          <li className=" flex space-x-3 ">
                            <div className="flex-1 space-x-3 flex">
                              <img src={mail} alt="" className="iconSize" />
                              <div>{item.email}</div>
                            </div>
                          </li>

                          <li className=" flex space-x-3 ">
                            <div className="flex-1 space-x-3 flex">
                              <img src={call} alt="" className="iconSize" />
                              <div>{item.phone}</div>
                            </div>
                          </li>
                        </ul>
                        <a href="#" className=" btn btn-primary btn-md text-lg">
                          Book A Seat
                        </a>
                      </div>
                    </div>
                  </div> */}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default EventDetails;
