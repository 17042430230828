import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Loader from "../Loader";
import { Tab } from "@headlessui/react";
import { Link } from "react-router-dom";

const Event = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {loading && <Loader />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>MoneYogi - Events</title>
      </Helmet>
      {/* <PageBanner title={"Events"} pageTitle="Events" /> */}
      {/* <EventsList /> */}
      <div className="nav-tab-wrapper tabs section-padding">
        <div className="container">
          <Tab.Group>
            <div className="nav-tab-wrapper tabCenter">
              <Tab.List
                as="ul"
                id="tabs-nav"
                className="course-tab blogTab mb-8"
              >
                <Tab
                  as="li"
                  className={({ selected }) => (selected ? "active" : null)}
                >
                  <a style={{ textAlign: "center", fontSize: "19px" }}>Past</a>
                </Tab>
                <Tab
                  as="li"
                  className={({ selected }) => (selected ? "active" : null)}
                >
                  <a style={{ textAlign: "center", fontSize: "19px" }}>
                    Upcoming
                  </a>
                </Tab>
              </Tab.List>
            </div>
            <Tab.Panels id="tabs-content">
              <Tab.Panel id="tab1" className="tab-content">
                <center>
                  <div className="hero-text">
                    <h1>Coming Soon</h1>
                    <br />
                    {/* <h3>We Will contact you very soon.</h3> */}
                    <Link className="back-home" to={"/"}>
                      Back To Home
                    </Link>
                  </div>
                </center>
              </Tab.Panel>
              <Tab.Panel id="tab2" className="tab-content">
                <center>
                  <div className="hero-text">
                    <h1>Coming Soon</h1>
                    <br />
                    {/* <h3>We Will contact you very soon.</h3> */}
                    <Link className="back-home" to={"/"}>
                      Back To Home
                    </Link>
                  </div>
                </center>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </>
  );
};

export default Event;
