import React, { useEffect } from "react";
import { q } from "../../constant/images";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Hire = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MoneYogi - Services</title>
      </Helmet>
      <div className="container">
        <div className="hire">
          <div className="text-center hireTitle">
            <h3>Want to invite MoneYogi</h3>
            <h3>TO YOUR EVENT?</h3>
          </div>
          <Link
            to="#"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button className="btn btn-header px-8 py-[11px] mt-4 text-center">
              INVITE MONEYOGI TO SPEAK
            </button>
          </Link>
          <div className="dataHire">
            <p style={{ color: "#000" }}>
              MoneYogi Suhas Harshe has established himself as a leading
              motivational and informative speaker.
            </p>
            <p style={{ color: "#000" }}>
              His unique skill for taking complicated issues and breaking them
              down into simple easy to understandable terms is one of the
              reasons he consistently receives the highest overall speaker
              rating at many high-profile events.
            </p>
            <p style={{ color: "#000" }}>
              With over 12+ years of experience conducting seminars and
              workshops, and delivering 220+ presentations, he has developed a
              unique style that simply WOWS audiences.
            </p>
          </div>
          <blockquote className="bg-primary p-8 rounded-md my-8 text-white">
            <img src={q} alt="" />
            <div className="text-2xl font-medium my-6">
              “The stage is my place to share with the world. I find solace in
              standing in front of hundreds of people and sharing my wealth of
              knowledge and experience in the area of finance”
            </div>
            {/* <div className=" flex items-center space-x-3">
            <span className=" inline-flex h-[3px] w-12 bg-white"></span>
            <span className=" text-xl font-semibold">Rosalina D. Jackson</span>
          </div> */}
          </blockquote>
          <div className="dataHire">
            <p style={{ color: "#000" }}>
              For those of you who would like to invite MoneYogi to speak at
              their event, you can submit a request to our media department
              below.
            </p>
          </div>
          <Link
            to="#"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button className="btn btn-header px-8 py-[11px] mt-4 text-center">
              INVITE MONEYOGI TO SPEAK
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Hire;
