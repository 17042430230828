import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useAPI } from "../../Context/apiContext";
import Feature from "../Feature";
import Testimonial from "../HomeThreeComponents/Testimonial";
import About from "../HomeTwoComponents/About";
import Loader from "../Loader";

const AboutOne = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {loading && <Loader />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>MoneYogi - About</title>
      </Helmet>
      {/* <PageBanner title={"About Us"} pageTitle="About Us" /> */}
      <About />
      {/* <div className="marginBottom">
        <Feature />
      </div> */}
      {/* <Counter /> */}
      {/* <Testimonials /> */}
      {/* <Testimonial /> */}
      {/* <Team /> */}
      {/* <Accordion /> */}
    </>
  );
};

export default AboutOne;
