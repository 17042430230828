import React, { useState } from "react";
import { Link } from "react-router-dom";
import { fbIcon, lnIcon, ytIcon } from "../constant/images";
import { useAPI } from "../Context/apiContext";
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";

const MobileMenu = ({ activeMenu, setActiveMenu, setShow }) => {
  const { logoData } = useAPI();

  const [toggleDrop, setToggleDrop] = useState(false);
  const [toggleEvent, setToggleEvent] = useState(false);
  const [toggleHire, setToggleHire] = useState(false);
  const [toggleSpeaking, setToggleSpeaking] = useState(false);
  return (
    <>
      <div className="openmobile-menu fixed top-0 h-screen pb-6 bg-white shadow-box2 w-[320px] overflow-y-auto flex flex-col z-[999] active-mobile-menu">
        <div className="flex justify-between flex-none">
          <Link to={"/"} className="brand-logo flex-none mr-10 mobile-bg">
            <img
              src={logoData.logo}
              alt="logo"
              style={{
                width: "250px",
                height: "110px",
                padding: "10px 10px",
              }}
            />
          </Link>
          <span
            className=" text-3xl text-white close_index cursor-pointer rt-mobile-menu-close"
            onClick={() => {
              setActiveMenu(!activeMenu);
            }}
          >
            <iconify-icon icon="fe:close"></iconify-icon>
          </span>
        </div>
        <div className="mobile-menu mt-6 flex-1 ">
          <ul className="menu-active-classNamees">
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <Link to={"/about"}>About</Link>
            </li>
            <li>
              <Link to={"/programs"}>Programs</Link>
            </li>
            <li>
              <Link to={"/blog"}>Blog</Link>
            </li>
            <li
              onClick={() => {
                setToggleHire(!toggleHire);
                setToggleDrop(false);
              }}
            >
              <Link
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                to={"#"}
              >
                Hire Moneyogi
                {toggleHire ? (
                  <MdOutlineKeyboardArrowDown className="iconRight" />
                ) : (
                  <MdOutlineKeyboardArrowRight className="iconRight" />
                )}
              </Link>
            </li>
            {toggleHire ? (
              <>
                <li>
                  <Link
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    to="/hire-moneyogi"
                  >
                    Services
                    <MdOutlineKeyboardArrowRight className="iconRight" />
                  </Link>
                </li>
                <li onClick={() => setToggleSpeaking(!toggleSpeaking)}>
                  <Link
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    to="#"
                  >
                    Speaking
                    {toggleSpeaking ? (
                      <MdOutlineKeyboardArrowDown className="iconRight" />
                    ) : (
                      <MdOutlineKeyboardArrowRight className="iconRight" />
                    )}
                  </Link>
                </li>
                {toggleSpeaking ? (
                  <>
                    <li>
                      <Link to="/speaking-questionnaire">
                        Speaking Questionnaire
                      </Link>
                    </li>
                  </>
                ) : null}
              </>
            ) : null}
            <li
              onClick={() => {
                setToggleDrop(!toggleDrop);
                setToggleHire(false);
              }}
            >
              <Link
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                to={"#"}
              >
                Media
                {toggleDrop ? (
                  <MdOutlineKeyboardArrowDown className="iconRight" />
                ) : (
                  <MdOutlineKeyboardArrowRight className="iconRight" />
                )}
              </Link>
            </li>
            {toggleDrop ? (
              <>
                <li>
                  <Link
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    to="/gallery-listing"
                  >
                    Gallery
                    <MdOutlineKeyboardArrowRight className="iconRight" />
                  </Link>
                </li>
                <li onClick={() => setToggleEvent(!toggleEvent)}>
                  <Link
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    to="#"
                  >
                    Events
                    {toggleEvent ? (
                      <MdOutlineKeyboardArrowDown className="iconRight" />
                    ) : (
                      <MdOutlineKeyboardArrowRight className="iconRight" />
                    )}
                  </Link>
                </li>
                {toggleEvent ? (
                  <>
                    <li>
                      <Link to="/past-events">Past</Link>
                    </li>
                    <li>
                      <Link to="/upcoming-events">Upcoming</Link>
                    </li>
                  </>
                ) : null}
              </>
            ) : null}
            <li>
              <Link to={"/testmonial"}>Success Stories</Link>
            </li>
            {/* <li>
              <Link to={"/resources"}>Resourses</Link>
            </li> */}
            <li>
              <Link to={"/contacts"}>Contacts</Link>
            </li>
            <div
              className="lg:block"
              style={{ marginLeft: "7px", marginTop: "8px" }}
            >
              <Link
                to={"#"}
                onClick={() => {
                  setShow(true);
                  setActiveMenu(!activeMenu);
                }}
                className="btn btn-header py-[10px] px-8"
              >
                Free eBook
              </Link>
            </div>
          </ul>
        </div>
        <div className=" flex-none pb-4">
          <div className=" text-center text-black font-semibold mb-2">
            Follow Us
          </div>
          <ul className="flex space-x-4 justify-center">
            <li>
              <a
                href={logoData.yt}
                target="_blank"
                rel="noreferrer noopener"
                className="flex h-10 w-10"
              >
                <img
                  src={ytIcon}
                  alt="twiter"
                  style={{ borderRadius: "6px" }}
                />
              </a>
            </li>
            <li>
              <a
                href={logoData.linkedin}
                target="_blank"
                rel="noreferrer noopener"
                className="flex h-10 w-10"
              >
                <img src={lnIcon} alt="twiter" />
              </a>
            </li>
            <li>
              <a
                href={logoData.fb}
                target="_blank"
                rel="noreferrer noopener"
                className="flex h-10 w-10"
              >
                <img src={fbIcon} alt="fbIcon" />
              </a>
            </li>
            {/* <li>
              <a href="#" className="flex h-10 w-10">
                <img src={pnIcon} alt="pnIcon" />
              </a>
            </li>
            <li>
              <a href="#" className="flex h-10 w-10">
                <img src={insIcon} alt="insIcon" />
              </a>
            </li> */}
          </ul>
        </div>
      </div>{" "}
      <div className={`rt-mobile-menu-overlay ${activeMenu && "active"}`}></div>
    </>
  );
};

export default MobileMenu;
