import React from "react";
import mailWhite from "../assets/images/svg/mail-white.svg";
import bigMail from "../assets/images/svg/big-mail.svg";
import user from "../assets/images/svg/user.png";
import phoneCall from "../assets/images/svg/phone-call.png";
import mail from "../assets/images/svg/mail.png";

const NewsLetter = ({
  newsletter,
  setNewsLetter,
  subscribeNewsletter,
  errorNewsletter,
  setErrorNewsletter,
  newsLetterChange,
  isNewsLetterValid,
}) => {
  return (
    <div className="header_bg py-14">
      <div className="container" style={{ position: "relative" }}>
        <div className="newsletterFlex">
          <div className="flex-none">
            <div className="flex items-center space-x-8 text-white max-w-[327px] mx-auto lg:mb-0 md:mb-10 mb-5">
              <div className=" flex-none ">
                <img src={bigMail} alt="" />
              </div>
              <div className="flex-1 lg:text-[33px] lg:leading-[51px] md:text-3xl text-2xl font-bold">
                Subscribe to My Newsletter
              </div>
            </div>
          </div>
          <div className="newsTop">
            <div className="positionNews">
              <div className="newsContainer">
                <div className="relative marginNews">
                  <span className="absolute left-0 top-1/2 -translate-y-1/2 ">
                    <img src={user} alt="" />
                  </span>
                  <input
                    type="text"
                    placeholder="Enter your name"
                    className="inputWidth border-b border-t-0 border-l-0 border-r-0 border-[#B2E2DF] ring-0 focus:ring-0 bg-transparent text-white
                                placeholder:text-[#B2E2DF] focus:outline-0 focus:border-[#B2E2DF] transition pl-8 text-lg inputNews"
                    // value={newsletter}
                    // onChange={newsLetterChange}
                    // onBlur={() => setErrorNewsletter(true)}
                  />
                </div>
                <div className="flex-1 relative marginNews">
                  <span className=" absolute left-0 top-1/2 -translate-y-1/2 ">
                    <img src={mail} alt="" />
                  </span>
                  <input
                    type="email"
                    placeholder="Enter your mail address"
                    className="inputWidth border-b border-t-0 border-l-0 border-r-0 border-[#B2E2DF] ring-0 focus:ring-0 bg-transparent text-white
                                placeholder:text-[#B2E2DF] focus:outline-0 focus:border-[#B2E2DF] transition pl-8 text-lg inputNews"
                  />
                </div>
                <div className="flex-1 relative ">
                  <span className=" absolute left-0 top-1/2 -translate-y-1/2 ">
                    <img src={phoneCall} alt="" />
                  </span>
                  <input
                    type="text"
                    placeholder="Enter your phone number"
                    className="inputWidth border-b border-t-0 border-l-0 border-r-0 border-[#B2E2DF] ring-0 focus:ring-0 bg-transparent text-white
                                placeholder:text-[#B2E2DF] focus:outline-0 focus:border-[#B2E2DF] transition pl-8 text-lg inputNews"
                  />
                </div>
              </div>
              <div className="newsContainer">
                <button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="btn bg-white text-secondary md:w-auto w-full btnNews"
                  // onClick={() => {
                  //   setErrorNewsletter(true);
                  // }}
                >
                  Subscribe Now
                </button>
              </div>
              {/* <div>
                <div className="flex-1 relative">
                  <span className=" absolute left-0 top-1/2 -translate-y-1/2 ">
                    <img src={user} alt="" />
                  </span>
                  <input
                    type="text"
                    placeholder="Enter your name"
                    className="inputWidth border-b border-t-0 border-l-0 border-r-0 border-[#B2E2DF] ring-0 focus:ring-0 bg-transparent text-white
                                placeholder:text-[#B2E2DF] focus:outline-0 focus:border-[#B2E2DF] transition pl-8 text-lg inputNews"
                    // value={newsletter}
                    // onChange={newsLetterChange}
                    // onBlur={() => setErrorNewsletter(true)}
                  />
                </div>
                <div style={{ marginBottom: "15px" }}>
                  {errorNewsletter ? (
                    isNewsLetterValid ? (
                      ""
                    ) : (
                      <p className="errorWhite">Email Is Required</p>
                    )
                  ) : null}
                </div>
              </div>
              <div>
                <div className="flex-1 relative">
                  <span className=" absolute left-0 top-1/2 -translate-y-1/2 ">
                    <img src={mail} alt="" />
                  </span>
                  <input
                    type="email"
                    placeholder="Enter your mail address"
                    className="inputWidth border-b border-t-0 border-l-0 border-r-0 border-[#B2E2DF] ring-0 focus:ring-0 bg-transparent text-white
                                placeholder:text-[#B2E2DF] focus:outline-0 focus:border-[#B2E2DF] transition pl-8 text-lg inputNews"
                  />
                </div>
                <div style={{ marginBottom: "15px" }}>
                  {errorNewsletter ? (
                    isNewsLetterValid ? (
                      ""
                    ) : (
                      <p className="errorWhite">Email Is Required</p>
                    )
                  ) : null}
                </div>
              </div>
              <div>
                <div className="flex-1 relative">
                  <span className=" absolute left-0 top-1/2 -translate-y-1/2 ">
                    <img src={phoneCall} alt="" />
                  </span>
                  <input
                    type="text"
                    placeholder="Enter your phone number"
                    className="inputWidth border-b border-t-0 border-l-0 border-r-0 border-[#B2E2DF] ring-0 focus:ring-0 bg-transparent text-white
                                placeholder:text-[#B2E2DF] focus:outline-0 focus:border-[#B2E2DF] transition pl-8 text-lg inputNews"
                  />
                </div>
                <div style={{ marginBottom: "15px" }}>
                  {errorNewsletter ? (
                    isNewsLetterValid ? (
                      ""
                    ) : (
                      <p className="errorWhite">Email Is Required</p>
                    )
                  ) : null}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
