import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Speaking = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MoneYogi - Speaking Questionnaire</title>
      </Helmet>
      <div className="pt-10">
        <h4
          className="p-5 loraFont"
          style={{
            textAlign: "center",
            color: "#000",
          }}
        >
          Speaking
          <span className="shape-bg" style={{ marginLeft: "0.7rem" }}>
            Questionnaire
          </span>
        </h4>
      </div>
      <div className="container pb-10">
        <div className="speakingMain">
          <h3>Why me?</h3>
          <ul>
            <li>
              # What do you think I know that will enlighten the audience?
            </li>
            <li># What do you want me to talk about?</li>
            <li># Is there an overall theme to the meeting?</li>
          </ul>
          <h3>Who’s the audience?</h3>
          <ul>
            <li># Do they have a common interest? </li>
            <li># Do they represent a single profession?</li>
            <li># Who’s my contact?</li>
            <li># Who will be introducing me?</li>
            <li># What kind of introduction will it be?</li>
          </ul>
          <h3>Who’s the audience?</h3>
          <ul>
            <li># Am I the only Speaker? </li>
            <li># Is it a multi-speaker event?</li>
            <li># Who are the other speakers, and what are their topics?</li>
            <li># Who will be introducing me?</li>
            <li># How much time do I have to speak?</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Speaking;
