import React, { useContext, useState, useEffect, createContext } from "react";

const APIContext = createContext();

export function APIContextProvider({ children }) {
  const [contactData, setContactData] = useState([]);
  const [sliderData, setSliderData] = useState([]);
  const [featureData, setFeatureData] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [homeAboutData, setHomeAboutData] = useState([]);
  const [homeAboutDes, setHomeAboutDes] = useState([]);
  const [topicData, setTopicData] = useState([]);
  const [testData, setTestData] = useState([]);
  const [coutersData, setCoutersData] = useState([]);
  const [blogsData, setBlogsData] = useState([]);
  const [logoData, setLogoData] = useState([]);
  const [aboutData, setAboutData] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [podcastsData, setPodcastsData] = useState([]);

  // getContactData
  const getContactData = async () => {
    return await fetch("https://moneyogi.com/admin/api/GetContactDetails")
      .then((res) => res.json())
      .then((res) => {
        setContactData(res.data);
      });
  };

  // getSliderData
  const getSliderData = async () => {
    return await fetch("https://moneyogi.com/admin/api/GetSliderDetails")
      .then((res) => res.json())
      .then((res) => {
        setSliderData(res.data);
      });
  };

  // getFeatureData
  const getFeatureData = async () => {
    return await fetch("https://moneyogi.com/admin/api/GetFeatures")
      .then((res) => res.json())
      .then((res) => {
        setFeatureData(res.data);
      });
  };

  // getServiceData
  const getServiceData = async () => {
    return await fetch("https://moneyogi.com/admin/api/GetServices")
      .then((res) => res.json())
      .then((res) => {
        setServicesData(res.data);
      });
  };

  // getHomeAboutData
  const getHomeAboutData = async () => {
    return await fetch("https://moneyogi.com/admin/api/GetHomeAboutDetails")
      .then((res) => res.json())
      .then((res) => {
        setHomeAboutData(res.data);
        setHomeAboutDes(res.data.abt_desc);
      });
  };

  // getTopicData
  const getTopicData = async () => {
    return await fetch("https://moneyogi.com/admin/api/GetServices")
      .then((res) => res.json())
      .then((res) => {
        setTopicData(res.data);
      });
  };

  // getTestData
  const getTestData = async () => {
    return await fetch("https://moneyogi.com/admin/api/GetTestimonials")
      .then((res) => res.json())
      .then((res) => {
        setTestData(res.data);
      });
  };

  // getCountersData
  const getCountersData = async () => {
    return await fetch("https://moneyogi.com/admin/api/GetCounters")
      .then((res) => res.json())
      .then((res) => {
        setCoutersData(res.data);
      });
  };

  // getBlogs
  const getBlogs = async () => {
    return await fetch("https://moneyogi.com/admin/api/GetBlogs")
      .then((res) => res.json())
      .then((res) => {
        setBlogsData(res.data);
      });
  };

  // getLogoData
  const getLogoData = async () => {
    return await fetch("https://moneyogi.com/admin/api/GetContactDetails")
      .then((res) => res.json())
      .then((res) => {
        setLogoData(res.data);
      });
  };

  // getAboutData
  const getAboutData = async () => {
    return await fetch("https://moneyogi.com/admin/api/GetAboutDetails")
      .then((res) => res.json())
      .then((res) => {
        setAboutData(res.data);
      });
  };

  // getVideos
  const getVideos = async () => {
    return await fetch("https://moneyogi.com/admin/api/GetVideos")
      .then((res) => res.json())
      .then((res) => {
        setVideoData(res.data);
      });
  };

  // getPodcasts
  const getPodcasts = async () => {
    return await fetch("https://moneyogi.com/admin/api/GetPodcasts")
      .then((res) => res.json())
      .then((res) => {
        setPodcastsData(res.data);
      });
  };

  const getMedia = async () => {
    return await fetch("https://moneyogi.com/admin/api/GetGallery")
      .then((res) => res.json())
      .then((res) => {
        setMediaData(res.data);
      });
  };

  useEffect(() => {
    getContactData();
    getSliderData();
    getFeatureData();
    getServiceData();
    getHomeAboutData();
    getTopicData();
    getTestData();
    getCountersData();
    getBlogs();
    getLogoData();
    getAboutData();
    getVideos();
    getPodcasts();
    getMedia();
  }, []);

  return (
    <APIContext.Provider
      value={{
        contactData,
        sliderData,
        featureData,
        servicesData,
        homeAboutData,
        topicData,
        testData,
        coutersData,
        blogsData,
        logoData,
        aboutData,
        videoData,
        podcastsData,
        mediaData,
        homeAboutDes,
      }}
    >
      {children}
    </APIContext.Provider>
  );
}

export function useAPI() {
  const context = useContext(APIContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
