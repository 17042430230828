import React from "react";

const Loader = () => {
  return (
      <div id="preloader">
        <div className="spinner"></div>
      </div>
  );
};

export default Loader;
