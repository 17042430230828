import React, { useState, useEffect } from "react";
import Loader from "../Loader";
import ServiceDetails from "../ServiceDetails";

const SingleService = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {loading && <Loader />}
      <ServiceDetails />
    </div>
  );
};

export default SingleService;
