import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Past = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MoneYogi - Past Events</title>
      </Helmet>
      <div className="container mt-10 mb-10">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] pt-1">
          <div className="bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm">
            <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
              <Link to="/event-detail">
                <img src="" alt="" className=" w-full h-full rounded-t-[8px]" />
              </Link>
            </div>
            <div className="course-content p-4">
              <Link to="/event-detail">
                <h4 className=" text-xl mb-3 font-bold">
                  Lorem ipsum dolor sit amet.
                </h4>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              </Link>
              <Link to="/event-detail">
                <button className="btn px-8 py-[11px] mt-4 btn-primary text-center">
                  Read More
                </button>
              </Link>
            </div>
          </div>
          <div className="bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm">
            <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
              <Link to="/event-detail">
                <img src="" alt="" className=" w-full h-full rounded-t-[8px]" />
              </Link>
            </div>
            <div className="course-content p-4">
              <Link to="/event-detail">
                <h4 className=" text-xl mb-3 font-bold">
                  Lorem ipsum dolor sit amet.
                </h4>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              </Link>
              <Link to="/event-detail">
                <button className="btn px-8 py-[11px] mt-4 btn-primary text-center">
                  Read More
                </button>
              </Link>
            </div>
          </div>
          <div className="bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm">
            <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
              <Link to="/event-detail">
                <img src="" alt="" className=" w-full h-full rounded-t-[8px]" />
              </Link>
            </div>
            <div className="course-content p-4">
              <Link to="/event-detail">
                <h4 className=" text-xl mb-3 font-bold">
                  Lorem ipsum dolor sit amet.
                </h4>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              </Link>
              <Link to="/event-detail">
                <button className="btn px-8 py-[11px] mt-4 btn-primary text-center">
                  Read More
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Past;
