/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import brand1 from "../assets/images/all-img/brands/1.svg";
import brand2 from "../assets/images/all-img/brands/2.svg";
import brand3 from "../assets/images/all-img/brands/3.svg";
import brand4 from "../assets/images/all-img/brands/4.svg";
import brand5 from "../assets/images/all-img/brands/5.svg";
import client1 from "../assets/images/Images/feature/client1.png";
import client2 from "../assets/images/Images/feature/client2.png";
import client3 from "../assets/images/Images/feature/client3.png";
import client4 from "../assets/images/Images/feature/client4.svg";
import client5 from "../assets/images/Images/feature/client5.png";

const Brands = ({ section_padding_bottom, section_padding_top }) => {
  return (
    <div className="brandMargin ">
      <div
        className={`brands-area ${section_padding_bottom} ${section_padding_top}`}
      >
        <div className="container">
          {/* mb-[50px] */}
          {/* <div
            className="text-center text-black text-3xl font-medium featureMargin"
            style={{ fontWeight: "600" }}
          >
            <span className="shape-bg mini">Featured</span> In
          </div> */}
          <div className="text-center">
            {/* <div className="mini-title">Start Learning From Today</div> */}
            <h4 className="column-title ">
              <span className="shape-bg" style={{ marginLeft: "8px" }}>
                Featured
              </span> In
            </h4>
          </div>
        </div>
        <div className="bgFeature">
          <ul className="flex flex-wrap items-center lg:justify-between justify-center ">
            {/* grayscale-[80] hover:grayscale-0 mb-6 last:mb-0 mr-6 last:mr-0    */}
            <li className="transition duration-150 ">
              <a href="#" className="block">
                <img src={client2} alt="brandImg" className="featuredImg featuredImgMargin" />
              </a>
            </li>
            <li className="transition duration-150 ">
              <a href="#" className="block">
                <img src={client4} alt="brandImg" className="featuredImg featuredImgMargin" />
              </a>
            </li>
            <li className="transition duration-150 ">
              <a href="#" className="block">
                <img src={client5} alt="brandImg" className="featuredImg featuredImgMargin" />
              </a>
            </li>
            <li className="transition duration-150 ">
              <a href="#" className="block">
                <img src={client3} alt="brandImg" className="featuredImg featuredImgMargin" />
              </a>
            </li>
            <li className="transition duration-150 ">
              <a href="#" className="block">
                <img src={client1} alt="brandImg" className="featuredImg" />
              </a>
            </li>
            {/* {[brand1, brand2, brand3, brand4].map((item, index) => (
              <li
                className=" mb-6 last:mb-0 mr-6 last:mr-0  transition duration-150 grayscale-[80] hover:grayscale-0 "
                key={index}
              >
                <a href="#" className=" block">
                  <img src={item} alt="brandImg" />
                </a>
              </li>
            ))} */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Brands;
