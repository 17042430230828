import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import FilteredCourse from "../FilteredCourse";
import Loader from "../Loader";

const Courses = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {loading && <Loader />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>MoneYogi - Programs</title>
      </Helmet>
      {/* <PageBanner title={"Services"} pageName="Services" /> */}
      <div className="nav-tab-wrapper tabs">
        <div className="container">
          <FilteredCourse
            classNameForTabOne={
              "grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]"
            }
            classNameForTabTwo={
              "grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-[30px]"
            }
          />
        </div>
      </div>
    </>
  );
};

export default Courses;
