import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";

const SubmitEnquiry = () => {
  const [nameEnq, setNameEnq] = useState("");
  const [phoneEnq, setPhoneEnq] = useState("");
  const [emailEnq, setEmailEnq] = useState("");
  const [messageEnq, setMessageEnq] = useState("");

  const nameEngChange = (evt) => {
    setNameEnq(evt.target.value);
  };
  const namePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPhoneEnq(value);
  };
  const emailEnqChange = (evt) => {
    setEmailEnq(evt.target.value);
  };
  const messageEnqChange = (evt) => {
    setMessageEnq(evt.target.value);
  };

  const isNameValid = nameEnq !== "";
  const isNumberValid = phoneEnq !== "";
  const isEmailValid = emailEnq !== "";
  const isMessageValid = messageEnq !== "";

  const [errorName, setErrorName] = useState(false);
  const [errorNumber, setErrorNumber] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const enquiryValue = {
    name: nameEnq,
    email: emailEnq,
    phone: phoneEnq,
    message: messageEnq,
  };

  const submitEnquiry = async () => {
    await axios
      .post(
        "https://applextechnologies.com/suhas_harshe/admin/api/SubmitEnquiry",
        enquiryValue,
        options
      )
      .then((res) => {
        if (isMessageValid == true) {
          console.log(res);
          // e.preventDefault();
        } else {
          console.log("False");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <form className=" md:grid-cols-2 grid grid-cols-1 gap-[30px] mt-6">
      <div>
        <input
          type="text"
          className=" from-control"
          placeholder="Name*"
          required
          value={nameEnq}
          onChange={nameEngChange}
          onBlur={() => setErrorName(true)}
        />
        <div style={{ marginBottom: "15px" }}>
          {errorName ? (
            isNameValid ? (
              ""
            ) : (
              <p className="errorMsg">Name Is Required</p>
            )
          ) : null}
        </div>
      </div>
      <div>
        <input
          type="number"
          className=" from-control"
          placeholder="Phone No*"
          required
          value={phoneEnq}
          onChange={namePhoneChange}
          onBlur={() => setErrorNumber(true)}
        />
        {errorNumber ? (
          isNumberValid ? (
            ""
          ) : (
            <p className="errorMsg">Number Is Required</p>
          )
        ) : null}
      </div>
      <div className="md:col-span-2 col-span-1">
        <input
          type="email"
          className=" from-control"
          placeholder="Email*"
          required
          value={emailEnq}
          onChange={emailEnqChange}
          onBlur={() => setErrorEmail(true)}
        />
        <div style={{ marginBottom: "15px" }}>
          {errorEmail ? (
            isEmailValid ? (
              ""
            ) : (
              <p className="errorMsg">Email Is Required</p>
            )
          ) : null}
        </div>
      </div>
      <div className="md:col-span-2 col-span-1">
        <textarea
          className=" from-control"
          placeholder="Your Message*"
          rows="5"
          required
          value={messageEnq}
          onChange={messageEnqChange}
          onBlur={() => setErrorMessage(true)}
        ></textarea>
        {errorMessage ? (
          isMessageValid ? (
            ""
          ) : (
            <p className="errorMsg">Message Is Required</p>
          )
        ) : null}
      </div>
      <button
        class="btn btn-primary mt-[10px]"
        name="submit"
        onClick={() => {
          setErrorName(true);
          setErrorNumber(true);
          setErrorEmail(true);
          setErrorMessage(true);
          submitEnquiry();
        }}
      >
        Send Message
      </button>
    </form>
  );
};

export default SubmitEnquiry;
