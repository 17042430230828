import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { call, mail, map } from "../../constant/images";
import ContactForm from "../ContactForm";
import Footer from "../Footer";
import Header from "../Header";
import Loader from "../Loader";
import PageBanner from "../PageBanner";
import SubmitEnquiry from "../SubmitEnquiry";

const ContactUs = () => {
  const [contactData, setContactData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    window.scrollTo(0, 0);
  }, []);

  const getContactData = async () => {
    return await fetch("https://moneyogi.com/admin/api/GetContactDetails")
      .then((res) => res.json())
      .then((res) => {
        setContactData(res.data);
      });
  };

  useEffect(() => {
    getContactData();
  }, []);

  const iFrameUrl = contactData.map;

  return (
    <>
      {loading && <Loader />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Suhas Harshe - Contact Us</title>
      </Helmet>
      {/* <PageBanner title={"Contact Us"} pageName="Contact" /> */}
      <div class="nav-tab-wrapper tabs  section-padding">
        <div class="container">
          <div class=" grid grid-cols-12 gap-[30px]">
            <div class="xl:col-span-5 lg:col-span-6 col-span-12 ">
              {/* <div class="mini-title">Contact Us</div> */}
              <h4 class="column-title ">
                Get In Touch <span class="shape-bg">Today</span>
              </h4>
              {/* <div>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered.
              </div> */}

              <p></p>
              <ul class=" list-item space-y-6 pt-8">
                <li class="flex">
                  <div class="flex-none mr-6">
                    <div class="">
                      <img src={mail} alt="" class="" />
                    </div>
                  </div>
                  <div class="flex-1">
                    <h4 class=" lg:text-xl text-lg mb-1">Email-Us :</h4>
                    <div>{contactData.email}</div>
                  </div>
                </li>
                <li class="flex">
                  <div class="flex-none mr-6">
                    <div class="">
                      <img src={call} alt="" class="" />
                    </div>
                  </div>
                  <div class="flex-1">
                    <h4 class=" lg:text-xl text-lg mb-1">Call Us:</h4>
                    <div>{contactData.phone}</div>
                  </div>
                </li>
                <li class="flex">
                  <div class="flex-none mr-6">
                    <div class="">
                      <img src={map} alt="" class="" />
                    </div>
                  </div>
                  <div class="flex-1">
                    <h4 class="lg:text-xl text-lg mb-1">Office :</h4>
                    <div>{contactData.address}</div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="xl:col-span-7 lg:col-span-6 col-span-12">
              <div class="bg-white shadow-box7 p-8 rounded-md">
                <SubmitEnquiry />
              </div>
            </div>
            <div className="iframe_map">
              <iframe src={contactData.map} frameborder="0"></iframe>
              {/* <iframe src={} frameborder="0"></iframe> */}
              {/* <div dangerouslySetInnerHTML={{ __html: contactData.map }} /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
