import React, { useState } from "react";

const ContactForm = ({
  commentName,
  commentNameChange,
  commentEmail,
  commentEmailChange,
  commentWebsite,
  commentWebsiteChange,
  commentComment,
  commentCommentChange,
  isNameValid,
  isEmailValid,
  isWebsiteValid,
  isCommentValid,
  errorName,
  setErrorName,
  errorEmail,
  setErrorEmail,
  errorWebsite,
  setErrorWebsite,
  errorMessage,
  setErrorMessage,
  addBlogComment,
}) => {
  return (
    <div className=" md:grid-cols-2 grid grid-cols-1 gap-[30px] mt-6 ">
      <div>
        <input
          type="text"
          className=" from-control"
          placeholder="Name*"
          value={commentName}
          onChange={commentNameChange}
          onBlur={() => setErrorName(true)}
        />
        <div style={{ marginBottom: "15px" }}>
          {errorName ? (
            isNameValid ? (
              ""
            ) : (
              <p className="errorMsg">Name Is Required</p>
            )
          ) : null}
        </div>
      </div>
      <div>
        <input
          type="email"
          className=" from-control"
          placeholder="Email*"
          value={commentEmail}
          onChange={commentEmailChange}
          onBlur={() => setErrorEmail(true)}
        />
        {errorEmail ? (
          isEmailValid ? (
            ""
          ) : (
            <p className="errorMsg">Email Is Required</p>
          )
        ) : null}
      </div>
      <div className="md:col-span-2 col-span-1">
        <input
          type="url"
          className=" from-control"
          placeholder="Website Address"
          value={commentWebsite}
          onChange={commentWebsiteChange}
          onBlur={() => setErrorWebsite(true)}
        />
        {errorWebsite ? (
          isWebsiteValid ? (
            ""
          ) : (
            <p className="errorMsg">Website Is Required</p>
          )
        ) : null}
      </div>
      <div className="md:col-span-2 col-span-1">
        <textarea
          className=" from-control"
          placeholder="Your Message*"
          rows="5"
          value={commentComment}
          onChange={commentCommentChange}
          onBlur={() => setErrorMessage(true)}
        ></textarea>
        {errorMessage ? (
          isCommentValid ? (
            ""
          ) : (
            <p className="errorMsg">Comment Is Required</p>
          )
        ) : null}
      </div>
      <button
        class="btn btn-primary mt-[10px]"
        name="submit"
        onClick={() => {
          setErrorName(true);
          setErrorEmail(true);
          setErrorWebsite(true);
          setErrorMessage(true);
          // addBlogComment();
        }}
      >
        Send Message
      </button>
    </div>
  );
};

export default ContactForm;
