import React from "react";
import { Link } from "react-router-dom";
import { useAPI } from "../Context/apiContext";

const TopHeader = ({ setShow, setShowNews }) => {
  const { logoData } = useAPI();

  return (
    <div className="container topHeader">
      <div>{/* <h4>life beyond money</h4> */}</div>
      <div>
        <li onClick={() => setShowNews(true)}>
          <Link to={"#"}>Newsletter</Link>
        </li>
        <li
          onClick={() => {
            setShow(true);
          }}
        >
          <Link to={"#"}>Free eBook</Link>
        </li>
        {/* <li>
          <Link to={"/moneyscan"}>Moneyscan</Link>
        </li> */}
        <li>
          <Link to={"/money-quotient"}>Money Quotient</Link>
        </li>
        {/* <li>
          <Link to={"/resources"}>Resourses</Link>
        </li> */}
        <li>
          <Link to={"/contacts"}>Contact Us</Link>
        </li>
        <div className="socialFlex">
          <a href={logoData.yt} target="_blank" rel="noreferrer noopener">
            <i class="fa-brands fa-youtube"></i>
          </a>
          <a href={logoData.linkedin} target="_blank" rel="noreferrer noopener">
            <i class="fa-brands fa-linkedin"></i>
          </a>
          <a href={logoData.fb} target="_blank" rel="noreferrer noopener">
            <i class="fa-brands fa-facebook"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
