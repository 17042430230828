import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Gallery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MoneYogi - Gallery</title>
      </Helmet>
      <div className="container mt-10 mb-10">
        <div className="gallery">
          <div className="cardGallery">
            <img
              src="https://images.unsplash.com/photo-1657996460340-d23c9cc0d15f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
              alt=""
            />
          </div>
          <div className="cardGallery">
            <img
              src="https://images.unsplash.com/photo-1692969959077-7b16772805c8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1528&q=80"
              alt=""
            />
          </div>
          <div className="cardGallery">
            <img
              src="https://images.unsplash.com/photo-1693249942577-7d44a2e26c50?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1460&q=80"
              alt=""
            />
          </div>
          <div className="cardGallery">
            <img
              src="https://images.unsplash.com/photo-1551005916-83ed42ca6b49?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
              alt=""
            />
          </div>
          <div className="cardGallery">
            <img
              src="https://images.unsplash.com/photo-1535752385016-16aa049b6a8d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1523&q=80"
              alt=""
            />
          </div>
          <div className="cardGallery">
            <img
              src="https://images.unsplash.com/photo-1682687220015-186f63b8850a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1375&q=80"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
