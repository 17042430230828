import React, { useState, useEffect } from "react";
import BlogPost from "../BlogPost";
import Loader from "../Loader";
import PageBanner from "../PageBanner";

const SingleBlog = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {loading && <Loader />}
      {/* <PageBanner title={"Blog Details"} pageName="Blog Details" /> */}
      <BlogPost />
    </>
  );
};

export default SingleBlog;
