import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Loader from "../Loader";

const Resources = () => {
  const [resourcesData, setResourcesData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getResources = async () => {
    return await fetch("/api/GetResources")
      .then((res) => res.json())
      .then((res) => {
        setResourcesData(res.data);
      });
  };

  useEffect(() => {
    getResources();
  }, []);

  return (
    <div>
      {loading && <Loader />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>MoneYogi - Resources</title>
      </Helmet>
      {/* <PageBanner title={"Resources"} pageTitle="Resources" /> */}
      <div className="resourceContianer container section-padding">
        <div className="flexResource">
          {resourcesData.map((item) => {
            return (
              <div className="resourse_card">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <button>
                  <a
                    href={item.file}
                    target="_blank"
                    rel="noreferrer"
                    className="btn px-8 py-[11px] btn-primary text-center"
                  >
                    Download
                  </a>
                </button>
              </div>
            );
          })}
        </div>
        {/* <div className="resourse_card">
            <h3>Documents Checklist</h3>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Recusandae neque consequatur sint et quibusdam,
            </p>
            <button className="btn px-8 py-[11px] mt-4 btn-primary text-center">
              Download
            </button>
          </div>
          <div className="resourse_card">
            <h3>Documents Checklist</h3>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Recusandae neque consequatur sint et quibusdam,
            </p>
            <button className="btn px-8 py-[11px] mt-4 btn-primary text-center">
              Download
            </button>
          </div>
          <div className="resourse_card">
            <h3>Documents Checklist</h3>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Recusandae neque consequatur sint et quibusdam,
            </p>
            <button className="btn px-8 py-[11px] mt-4 btn-primary text-center">
              Download
            </button>
          </div> */}
      </div>
      {/* </div> */}
    </div>
  );
};

export default Resources;
