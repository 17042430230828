import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import footerLogo from "../assets/images/logo/footer-logo.svg";

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <footer className="header_bg">
      {/* <div className="section-padding container">
        <div className="grid grid-cols-1 gap-7 md:grid-cols-2 lg:grid-cols-3">
          <div className="single-footer">
            <div className="lg:max-w-[270px]">
              <a href="#" className="mb-10 block">
                <img src={logoData.logo} alt="" />
              </a>
              <p>
                Lorem ipsum amet, consectetur adipiscing elit. Suspendis varius
                enim eros elementum tristique. Duis cursus.
              </p>
              <ul className="flex space-x-4 pt-8">
                <li>
                  <a
                    href={logoData.fb}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
                  >
                    <iconify-icon icon="bxl:facebook"></iconify-icon>
                  </a>
                </li>
                <li>
                  <a
                    href={logoData.linkedin}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
                  >
                    <iconify-icon icon="bxl:linkedin"></iconify-icon>
                  </a>
                </li>
                <li>
                  <a
                    href={logoData.yt}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
                  >
                    <i class="fa-brands fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="single-footer">
            <div className="flex space-x-[80px]">
              <div className="flex-1 lg:flex-none">
                <h4 className="mb-8 text-2xl font-bold text-white">Links</h4>
                <ul className="list-item space-y-5">
                  <li>
                    <Link to={"/suhas_harshe/"}>Home</Link>
                  </li>
                  <li>
                    <Link to={"/suhas_harshe/about"}>About Us</Link>
                  </li>
                  <li>
                    <Link to="#">Pricing</Link>
                  </li>
                  <li>
                    <Link to={"/suhas_harshe/services"}>Services</Link>
                  </li>
                  <li>
                    <Link to={"/suhas_harshe/contacts"}>Contact Us</Link>
                  </li>
                  <li>
                    <Link to={"/suhas_harshe/blog"}>Blog</Link>
                  </li>
                </ul>
              </div>
              <div className="flex-1 lg:flex-none">
                <h4 className="mb-8 text-2xl font-bold text-white">Legal</h4>
                <ul className="list-item space-y-5">
                  <li>
                    <a href="#">Legal</a>
                  </li>
                  <li>
                    <a href="#">Tearms of Use</a>
                  </li>
                  <li>
                    <Link to={"/suhas_harshe/terms-conditions"}>
                      Tearm & Condition
                    </Link>
                  </li>
                  <li>
                    <a href="#">Payment Method</a>
                  </li>
                  <li>
                    <Link to={"/suhas_harshe/privacy-policy"}>
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="single-footer">
            <h4 className="mb-8 text-2xl font-bold text-white">Newsletter</h4>
            <div className="mb-8">
              Join over <span className="text-primary underline">68,000</span>{" "}
              people getting our emails Lorem ipsum dolor sit amet consectet
            </div>
            <div className="mb-4 flex items-center rounded-md bg-white py-[10px] pr-[10px] pl-6 shadow-e1">
              <div className="flex-none">
                <span className=" ">
                  <img src="assets/images/icon/mail.svg" alt="" />
                </span>
              </div>
              <div className="flex-1">
                <input
                  type="text"
                  placeholder="Enter your mail"
                  className="border-none focus:ring-0"
                  value={newsletter}
                  onChange={(event) => setNewsLetter(event.target.value)}
                />
              </div>
            </div>
            <button
              className="btn btn-primary block w-full text-center"
              onClick={subscribeNewsletter}
            >
              Subscribe Now
            </button>
          </div>
        </div>
      </div> */}
      <div className="container border-t border-white border-opacity-[0.1] py-8 text-center text-base header_bg">
        <div className="footerFlex">
          &copy; Copyright {year} | Moneyogi Suhas Harshe | All Rights Reserved
          <p>
            Designed & Developed By{" "}
            <a
              href="https://applexinfotech.com/"
              target="_blank"
              rel="noreffrer"
            >
              Applex Infotech
            </a>
          </p>
          <div className="footerFlex">
            <li>
              <Link to={"/terms-conditions"}>Terms & Conditions</Link>
            </li>
            <li>
              <Link to={"/privacy-policy"}>Privacy Policy</Link>
            </li>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
