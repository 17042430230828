import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { MdOutlineLocationOn, MdDateRange } from "react-icons/md";

const EventDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <>
        <div className="nav-tab-wrapper tabs  section-padding">
          <div className="container rel">
            <div className="grid grid-cols-12 gap-[30px]">
              <div className="lg:col-span-8 col-span-12">
                <div className="single-course-details">
                  <div className="xl:h-[470px] h-[350px] mb-10 course-main-thumb">
                    <img
                      src=""
                      alt=""
                      className=" rounded-md object-fut w-full h-full block"
                    />
                  </div>
                  <div className=" mb-6"></div>
                  <h2>Lorem ipsum consectetur.</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Repellendus, repellat necessitatibus. Sequi, suscipit est
                    velit adipisci totam officiis perferendis assumenda?
                  </p>
                  <div
                    className="author-meta mt-6 sm:flex  lg:space-x-16 sm:space-x-5 space-y-5 
       sm:space-y-0 items-center"
                  ></div>
                </div>
              </div>
              <div className="lg:col-span-4 col-span-12 widgetPosition widgetPosi">
                <div
                  className="sidebarWrapper space-y-[30px]"
                  style={{ marginBottom: "25px" }}
                >
                  <div className="wdiget custom-text space-y-5 ">
                    <p
                      style={{
                        lineHeight: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "5px",
                          marginLeft: "-0.2rem",
                          fontSize: "27px",
                        }}
                      >
                        <MdOutlineLocationOn />
                      </div>
                      Event Place - Place Name
                    </p>
                    <p
                      style={{
                        lineHeight: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "7px",
                          fontSize: "22px",
                        }}
                      >
                        <MdDateRange />
                      </div>
                      Date - 22 - 02 - 2023
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default EventDetail;
