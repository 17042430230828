import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  bs1,
  bs2,
  bs3,
  user3,
  comment,
  q,
  calender,
  clockIcon,
  fbIcon,
  pnIcon,
  twIcon,
  insIcon,
  rc3,
  rc1,
  insIcon1,
  insIcon3,
  insIcon2,
  search,
  rc2,
  insIcon4,
  insIcon5,
  insIcon6,
} from "../constant/images";
import ContactForm from "./ContactForm";
import Loader from "./Loader";
import { useAPI } from "../Context/apiContext";

const BlogPost = () => {
  const [blogData, setBlogData] = useState([]);
  const [commentName, setCommentName] = useState("");
  const [commentEmail, setCommentEmail] = useState("");
  const [commentWebsite, setCommentWebsite] = useState("");
  const [commentComment, setCommentComment] = useState("");

  const { contactData } = useAPI();

  const { blog_id } = useParams();

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const blogValue = {
    blog_id: blog_id,
  };

  const getBlogData = async () => {
    await axios
      .post("https://moneyogi.com/admin/api/GetBlogDetail", blogValue, options)
      .then((res) => {
        setBlogData(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const commentNameChange = (evt) => {
    setCommentName(evt.target.value);
  };
  const commentEmailChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setCommentEmail(value);
  };
  const commentWebsiteChange = (evt) => {
    setCommentWebsite(evt.target.value);
  };
  const commentCommentChange = (evt) => {
    setCommentComment(evt.target.value);
  };

  const isNameValid = commentName !== "";
  const isEmailValid = commentEmail !== "";
  const isWebsiteValid = commentWebsite !== "";
  const isCommentValid = commentComment !== "";

  const [errorName, setErrorName] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorWebsite, setErrorWebsite] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const commentValues = {
    blog_id: blog_id,
    email: commentEmail,
    name: commentName,
    website: commentWebsite,
    comment: commentComment,
  };

  const addBlogComment = async () => {
    await axios
      .post(
        "https://moneyogi.com/admin/api/SaveBlogComment",
        commentValues,
        options
      )
      .then((res) => {
        if (
          (isNameValid == true,
          isEmailValid == true,
          isCommentValid == true,
          isWebsiteValid == true)
        ) {
          swal({ title: res.data.RESPONSE_MSG, icon: "success", button: "Ok" });
        } else {
          console.log("FALSE");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getBlogData();
  }, []);

  return (
    <>
      <div className="nav-tab-wrapper tabs section-padding">
        {blogData.map((item) => {
          return (
            <div className="container">
              <div className="grid grid-cols-3 gap-[30px]">
                <div className="lg:col-span-8 col-span-12">
                  <div className="bg-[#F8F8F8] rounded-md">
                    <img
                      src={item.image}
                      alt=""
                      className="rounded-t-md mb-10"
                      style={{ width: "100%" }}
                    />
                    <div className="px-10 pb-10">
                      <div className="flex  flex-wrap  xl:space-x-10 space-x-5 mt-6 mb-6">
                        <a className=" flex items-center space-x-2" href="#">
                          <img src={calender} alt="" />
                          <span>{item.date}</span>
                        </a>
                      </div>
                      <h3>{item.title}</h3>
                      <div
                        className="mt-6"
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      />
                    </div>
                  </div>

                  <div className=" mt-14">
                    <h3 className=" mb-6">Comments</h3>
                    <ul className="comments space-y-4">
                      <li className="block border-b border-[#ECECEC] pb-6 mt-6 last:border-0 last:pb-0">
                        {item.comments.map((comment) => {
                          return (
                            <div className="comments mt-6 border-t border-[#ECECEC]">
                              <div className="flex mt-6">
                                <div className="flex-none">
                                  <div className="h-[72px] w-[72px] rounded-full mr-6">
                                    <div className="commentProfile">
                                      <span>G</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-1">
                                  <div className="flex flex-wrap justify-between mb-2">
                                    <div>
                                      <span className="text-xl  font-semibold text-black block mb-1">
                                        {comment.name}
                                      </span>
                                      <span className=" block">
                                        {comment.reply_datetime}
                                      </span>
                                    </div>
                                  </div>
                                  <p>{comment.comment}</p>
                                </div>
                              </div>
                              {comment.reply == "" ? null : (
                                <ul className="comments mt-6 space-y-4 ml-14 border-t border-[#ECECEC]">
                                  <li className="block border-b border-[#ECECEC] pb-6 mt-6 last:border-0 last:pb-0">
                                    <div className="flex">
                                      <div className="flex-none">
                                        <div className="h-[72px] w-[72px] rounded-full mr-6">
                                          <div className="adminProfile">
                                            <img
                                              src={contactData.favicon}
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex-1">
                                        <div className="flex flex-wrap justify-between mb-2">
                                          <div>
                                            <span className="text-xl  font-semibold text-black block mb-1">
                                              Admin
                                            </span>
                                            <span className=" block">
                                              {comment.reply_datetime}
                                            </span>
                                          </div>
                                        </div>
                                        <p>{comment.reply}</p>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              )}
                            </div>
                          );
                        })}
                      </li>
                    </ul>
                  </div>
                  <div className=" mt-14">
                    <h3 className=" mb-4">Leave A Reply</h3>
                    <div>
                      Your email address will not be published. Requires fields
                      are marked*
                    </div>
                    <ContactForm
                      commentName={commentName}
                      commentNameChange={commentNameChange}
                      commentEmail={commentEmail}
                      commentEmailChange={commentEmailChange}
                      commentWebsite={commentWebsite}
                      commentWebsiteChange={commentWebsiteChange}
                      commentComment={commentComment}
                      commentCommentChange={commentCommentChange}
                      addBlogComment={addBlogComment}
                      isNameValid={isNameValid}
                      isEmailValid={isEmailValid}
                      isWebsiteValid={isWebsiteValid}
                      isCommentValid={isCommentValid}
                      errorName={errorName}
                      setErrorName={setErrorName}
                      errorEmail={errorEmail}
                      setErrorEmail={setErrorEmail}
                      errorWebsite={errorWebsite}
                      setErrorWebsite={setErrorWebsite}
                      errorMessage={errorMessage}
                      setErrorMessage={setErrorMessage}
                    />
                  </div>
                </div>
                <div className="lg:col-span-4 col-span-12">
                  <div className="sidebarWrapper space-y-[30px]"></div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default BlogPost;
