import React from "react";

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="arrowLeft" onClick={onClick}>
      <i class="fa-solid fa-arrow-left"></i>
    </div>
  );
};

export default PrevArrow;
